<div class="container-filter">
  <div class="input-search-box">
    <input class="input-search" type="text" placeholder="Buscar propriedade?" [(ngModel)]="filter">
    <mat-icon class="icon-search material-symbols-rounded" (click)="search()">search</mat-icon>
  </div>
</div>


<div class="mat-elevation-z2">
  <table mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="nome">
      <th mat-header-cell class="nome-column" *matHeaderCellDef id="nome">Nome</th>
      <td mat-cell class="nome-column" *matCellDef="let element"> {{ element.nome }}</td>
    </ng-container>

    <ng-container matColumnDef="local">
      <th mat-header-cell class="local-column" *matHeaderCellDef id="local">Local</th>
      <td mat-cell class="local-column" *matCellDef="let element"> {{ element?.local }}</td>
    </ng-container>

    <ng-container matColumnDef="tanques">
      <th mat-header-cell class="tanque-column" *matHeaderCellDef id="tanques">Tanques</th>
      <td mat-cell class="tanque-column" *matCellDef="let element"> {{ element?.qntTanques }} {{ element?.qntTanques > 1 ? 'tanques' : 'tanque' }}</td>
    </ng-container>

    <ng-container matColumnDef="detalhe">
      <th mat-header-cell class="detalhe-column" *matHeaderCellDef id="header-right">Detalhes</th>
      <td mat-cell class="detalhe-column" *matCellDef="let element" id="detalhe">
        <mat-icon class="icon-search-info material-symbols-rounded" (click)="redirectToDetails(element?.id)">search</mat-icon>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <div id="paginacao" class="flex-align-itens-center">
    <mat-icon class="arrow-table-back material-symbols-rounded" (click)="nextOrBack(false)">outbound</mat-icon>
    <div class="flex-align-itens-center">
      <ng-container *ngFor="let number of getSequence(totalPage)">
        <span id="paginacao-number" (click)="paginado(number)">{{ number }}</span>
      </ng-container>
    </div>
    <mat-icon class="arrow-table-next material-symbols-rounded" (click)="nextOrBack(true)">outbound</mat-icon>
  </div>
</div>

