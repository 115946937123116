<div style="position: relative; min-height: 85vh">
  <div class="container-header">
    <div class="back-box">
      <a href="">
        <mat-icon class="icon back-icon material-symbols-rounded">arrow_back</mat-icon>
      </a>
      <span class="back-text">Voltar aos detalhes do produtor</span>
    </div>
    <!-- <div class="register-info">
      <span class="tec-info">
        <span>Cadastrado pelo técnico: </span>
        <span class="tec-name">Diogo Garbo</span>
      </span>

      <span class="date-info">
        <span>Última edição: </span>
        <span class="edited-date">08 de Maio, 2024</span>
      </span>
    </div> -->
  </div>

  <div class="container-form">
    <span style="font-size: 14px; color: #6D6D6D">Dados da Propriedade</span>
    <form [formGroup]="form">
      <div class="row g-3">
        <div class="col-6">
          <label id="nomeLabel" for="nome" class="form-label required">Nome</label>
          <div class="input-group">
            <input type="text" class="form-control" [ngClass]="{'click-disabled': isEditando}" formControlName="nome"
              id="nome" aria-describedby="nomeImage">
            <mat-icon class="icon edit-icon material-symbols-rounded" *ngIf="isEditando"
              (click)="enableEdit('nome')">edit</mat-icon>
          </div>
        </div>

        <div class="col-6">
          <label id="produtor" for="produtor" class="form-label required">Produtor</label>
          <select class="form-select" formControlName="produtor">
            <option *ngFor="let produtor of produtores" [value]="produtor.id">{{ produtor.nome }}</option>
          </select>
        </div>
      </div>

      <div class="row g-3">
        <div class="col-6" formGroupName="endereco">
          <label for="logradouro" class="form-label required">Logradouro</label>
          <div class="input-group">
            <input type="text" class="form-control" [ngClass]="{'click-disabled': isEditando}"
              formControlName="logradouro" id="logradouro">
            <mat-icon class="icon edit-icon material-symbols-rounded" *ngIf="isEditando"
              (click)="enableEdit('logradouro')">edit</mat-icon>
          </div>
        </div>

        <div class="col-2" formGroupName="endereco">
          <label for="cep" class="form-label required">CEP</label>
          <div class="input-group">
            <input type="text" class="form-control required" [ngClass]="{'click-disabled': isEditando}"
              formControlName="cep" id="cep" appCepMask (blur)="getEnderecoViaCep()" [maxLength]="9">
            <mat-icon class="icon edit-icon material-symbols-rounded" *ngIf="isEditando"
              (click)="enableEdit('cep')">edit</mat-icon>
          </div>
        </div>

        <div class="col-4" formGroupName="endereco">
          <label for="localidade" class="form-label required">Cidade</label>
          <div class="input-group">
            <input type="text" class="form-control" [ngClass]="{'click-disabled': isEditando}"
              formControlName="localidade" id="localidade">
            <mat-icon class="icon edit-icon material-symbols-rounded" *ngIf="isEditando"
              (click)="enableEdit('localidade')">edit</mat-icon>
          </div>
        </div>
      </div>

      <div class="row g-3" formGroupName="endereco">
        <div class="col-6">
          <label for="complemento" class="form-label">Complemento</label>
          <div class="input-group" style="padding-bottom: 0">
            <input type="text" class="form-control" [ngClass]="{'click-disabled': isEditando}"
              formControlName="complemento" id="complemento" style="margin-bottom: 0">
            <mat-icon class="icon edit-icon material-symbols-rounded" *ngIf="isEditando"
              (click)="enableEdit('complemento')">edit</mat-icon>
          </div>
          <span style="margin-top: 5px; font-size: 12px; color: #52525B;">Em caso de área rural, informar latitude e
            longitude</span>
        </div>

        <div class="col-2">
          <label for="numero" class="form-label required">Número</label>
          <div class="input-group">
            <input type="text" class="form-control" [ngClass]="{'click-disabled': isEditando}" formControlName="numero"
              id="numero">
            <mat-icon class="icon edit-icon material-symbols-rounded" *ngIf="isEditando"
              (click)="enableEdit('numero')">edit</mat-icon>
          </div>
        </div>

        <div class="col-2">
          <label for="bairro" class="form-label required">Bairro</label>
          <div class="input-group">
            <input type="text" class="form-control" [ngClass]="{'click-disabled': isEditando}" formControlName="bairro"
              id="bairro">
            <mat-icon class="icon edit-icon material-symbols-rounded" *ngIf="isEditando"
              (click)="enableEdit('bairro')">edit</mat-icon>
          </div>
        </div>

        <div class="col-2">
          <label for="uf" class="form-label required">Estado</label>
          <div class="input-group">
            <input type="text" class="form-control" [ngClass]="{'click-disabled': isEditando}" formControlName="uf"
              id="uf" [maxLength]="2">
            <mat-icon class="icon edit-icon material-symbols-rounded" *ngIf="isEditando"
              (click)="enableEdit('uf')">edit</mat-icon>
          </div>
        </div>
      </div>
    </form>

    <div class="container-buttons">
      <ng-container *ngIf="exibirTanque">
        <!-- <div class="exclude-button-box">
          <mat-icon class="exclude-icon material-symbols-rounded">delete</mat-icon>
          <button class="button-no-border exclude-button" style="margin-right: 10px">Excluir propriedade</button>
        </div> -->
      </ng-container>
      <div class="buttons-box">
        <button class="button-no-border" style="margin-right: 10px" (click)="cleanInput()">Limpar</button>
        <button [disabled]="!form.valid || form.pristine" (click)="onSubmit()"
          [ngClass]="{'button-disabled': !form.valid || form.pristine}">
          {{ isEditando ? 'Salvar Alterações' : 'Cadastrar propriedade' }}
        </button>
      </div>
    </div>
  </div>


  <ng-container *ngIf="exibirTanque && tanques.length == 0">
    <div class="new-buttons-box">
      <button (click)="newTanque()" style="width: 100%">Novo tanque</button>
      <!-- <button class="button-white">Novo equipamento</button> -->
    </div>
  </ng-container>

  <ng-container *ngIf="tanques.length > 0 && loteDetail == null">
    <div class="iniciar-lote-container">
      <div class="iniciar-lote-container-buttons-box">
        <select id="tanqueSelecionado" class="select-tanque" [(ngModel)]="tanqueSelecionado" (change)="getLoteDetail()">
          <option *ngFor="let tanque of tanques" [value]="tanque.id">
            {{ tanque.nome }}
          </option>
        </select>
        <button class="new-tanque-button" (click)="newTanque()">Novo Tanque</button>
      </div>

      <button (click)="iniciarLote()" [ngClass]="{'button-disabled': loteDetail}" [disabled]="loteDetail">{{ loteDetail ? 'Iniciar lote' : 'Lote já iniciado' }}</button>
    </div>
  </ng-container>

  <ng-container *ngIf="loteDetail != null">
    <div class="lote-iniciado-container">
      <div class="lote-iniciado-container-header">
        <select id="tanqueSelecionado" class="select-tanque" [(ngModel)]="tanqueSelecionado">
          <option *ngFor="let tanque of tanques" [value]="tanque.id">
            {{ tanque.nome }}
          </option>
        </select>

        <div>
          <button class="new-tanque-button" (click)="newTanque()">Novo Tanque</button>
        </div>
      </div>

      <div class="divisor-line"></div>

      
      <div class="lote-iniciado-container-footer">
        <span>Lote {{ loteDetail.id}}</span>
        <div>
          <div class="info-lote-iniciado-container">
            <span class="info-lote-iniciado-title">Data de alojamento</span>
            <span class="info-lote-iniciado">{{ loteDetail?.dataAlojamento | dateFormat}}</span>
          </div>
          <div class="info-lote-iniciado-container">
            <span class="info-lote-iniciado-title">Peso de abate esperado</span>
            <span class="info-lote-iniciado">{{ loteDetail?.pesoAbateEsperado }}</span>
          </div>
          <div class="info-lote-iniciado-container">
            <span class="info-lote-iniciado-title">Remessa total</span>
            <span class="info-lote-iniciado">{{ loteDetail?.qtdRecebida }}</span>
          </div>
          <div class="info-lote-iniciado-container">
            <span class="info-lote-iniciado-title">Espécie</span>
            <span class="info-lote-iniciado">{{ loteDetail?.especie }}</span>
          </div>

          <button class="white-button" (click)="redirectToDetails(loteDetail?.id)">Ver detalhes</button>
        </div>
      </div>
 

    </div>
  </ng-container>

  <ng-container *ngIf="exibirLote">
    <div class="container-biometria">
      <app-tabs-biometrias [tanqueId]="tanqueSelecionado"></app-tabs-biometrias>
    </div>

  </ng-container>


  <ng-container *ngIf="tanques.length > 0">
    <div class="container-historic">
      <span>Histórico de lotes finalizados</span>

      <app-table-historico-lote [propriedadeId]="propriedadeId"></app-table-historico-lote>
    </div>
  </ng-container>

</div>
