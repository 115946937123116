import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ComprovanteService } from 'src/app/comprovante/comprovante.service';
import { LoteService } from 'src/app/lote/lote.service';
import { ExcelService } from "../../excel.service";
import { ProdutorService } from "../../produtor/produtor.service";
import { StorageService } from "../../storage.service";
import { TanqueNovoService } from "../../tanqueNovo.service";

@Component({
  selector: 'app-produtor-home',
  templateUrl: './produtor-home.component.html',
  styleUrls: ['./produtor-home.component.scss']
})
export class ProdutorHomeComponent implements OnInit, AfterViewInit {
  selectedTanque: any;
  selectedTanqueBiometria: any;
  produtores: any[] = [];
  tanques: any[];
  tanquesComLoteEmAberto: any[];
  tanquesComLoteValidacao: any[];
  waitingTech: boolean = false;
  loteEmAberto: true;
  loteDetail: any;
  propriedadeId: string;
  selectedTanqueItem: any;

  constructor(private tanqueService: TanqueNovoService,
    private produtorService: ProdutorService,
    private comprovanteService: ComprovanteService,
    private excelService: ExcelService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private loteService: LoteService,
    private storage: StorageService) {

  }

  ngOnInit() {
    this.tanqueService.findLotesEmValidacaoByProdutorId(this.storage.getUserId()).subscribe(data => {
      this.tanquesComLoteValidacao = data;
      if (this.tanquesComLoteValidacao.length == 0) {
        this.waitingTech = true;
      }

    });

    this.tanqueService.findLotesEmAbertoByProdutorId(this.storage.getUserId()).subscribe(data => {
      this.tanquesComLoteEmAberto = data;
      this.selectedTanque = this.tanquesComLoteEmAberto[0].id;
      this.updatePropriedadeId();

    });
  }

  updatePropriedadeId() {
    this.selectedTanqueItem = this.tanquesComLoteEmAberto.find(tanque => tanque.id === this.selectedTanque);
    if (this.selectedTanqueItem) {
      this.propriedadeId = this.selectedTanqueItem.propriedadeId;


      this.loteService.findDetailByLoteId(this.selectedTanqueItem.loteId).subscribe(data => {
        this.loteDetail = data;
        this.cdr.detectChanges()
      });

    }
  }

  onTanqueChange() {
    this.updatePropriedadeId();
  }

  checkLoteAceito() {

    if (localStorage.getItem('loteAceito')) {
      this.comprovanteService.emAberto(localStorage.getItem('loteAceito')).subscribe(data => {
        this.loteEmAberto = data;
        localStorage.removeItem('loteAceito');
      });
    }

  }

  ngAfterViewInit() {
    this.checkLoteAceito();
  }

  redirectToDetails(id: number) {
    this.router.navigate(['/lote/detalhes', id]);
  }

  newTanque() {
    this.router.navigate(['/tanque/cadastrar']);
  }

  selectOpen() {
    document.querySelector('.select-title').classList.toggle('select-title-open');
    document.querySelector('.menu-arrow-icon-select').classList.toggle('menu-arrow-icon-select-open');

    document.querySelector('.select-infos').classList.toggle('select-infos-open');
  }

  onDownload() {
    this.excelService.downloadPdf().subscribe(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'arquivo.pdf';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    });
  }
}
