import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { StorageService } from "../../storage.service";
import { MedicaoNovoService } from '../medicao-novo.service';

@Component({
  selector: 'app-table-registro-historico',
  templateUrl: './table-registro-historico.component.html',
  styleUrls: ['./table-registro-historico.component.scss']
})
export class TableRegistroHistoricoComponent implements OnInit {

  dataSourceAgua = new MatTableDataSource<any>;
  dataSourceBiometria = new MatTableDataSource<any>;
  dataSourceTrato = new MatTableDataSource<any>;
  dataSourceEstoque = new MatTableDataSource<any>;

  displayedColumnsAgua: string[] = ['data', 'hora', 'ph', 'amonia', 'nitrito', 'alcalinidade', 'transparencia', 'oxigenio', 'temperatura'];

  displayedColumnsBiometria: string[] = ['dtMedicao', 'hora', 'peixePorAmostra', 'mortalidade', 'mortalidadeAcumulada', 'qntPeixe', 'pesoMedio', 'biomassaTotal', 'ganhoPeso', 'racaoTotal', 'ca', 'gpd'];

  displayedColumnsTrato: string[] = ['data', 'hora', 'temperatura', 'oxigenio', 'racaoOfertada', 'racaoTotalDespejada', 'racaoRestanteSilo'];

  displayedColumnsEstoque: string[] = ['dtRecebimento', 'qtdRecebida', 'tipoRacao', 'nrmNf'];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  pageNumber: number = 0;
  totalPage: number;
  size: number = 5;
  filter: string;

  constructor(private service: MedicaoNovoService,
              private storage: StorageService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.service.historicoAgua(16, this.pageNumber, this.size).subscribe({
      next: (page) => {
        this.dataSourceAgua.data = page.content
        this.pageNumber = page.pageable.pageNumber
        this.totalPage = page.totalPages
      }, error: () => {
      }
    });

    this.service.historicoBiometria(16, this.pageNumber, this.size).subscribe({
      next: (page) => {
        this.dataSourceBiometria.data = page.content
        this.pageNumber = page.pageable.pageNumber
        this.totalPage = page.totalPages
      }, error: () => {
      }
    });

    this.service.historicoTrato(16, this.pageNumber, this.size).subscribe({
      next: (page) => {
        this.dataSourceTrato.data = page.content
        this.pageNumber = page.pageable.pageNumber
        this.totalPage = page.totalPages
      }, error: () => {
      }
    });

    this.service.historicoEstoque(16, this.pageNumber, this.size).subscribe({
      next: (page) => {
        this.dataSourceEstoque.data = page.content
        this.pageNumber = page.pageable.pageNumber
        this.totalPage = page.totalPages
      }, error: () => {
      }
    });

  }

  exibirQuadrado() {

  }

  nextOrBack(isAvancar: boolean) {
    let page = isAvancar ? this.pageNumber + 1 : this.pageNumber - 1;

    // this.service.findHistorico(this.storage.getUserId(), page, this.size).subscribe({
    //   next: (page) => {
    //     this.dataSource.data = page.content
    //     this.pageNumber = page.pageable.pageNumber
    //   }, error: () => {
    //   }
    // });
  }

  getSequence(num: number): number[] {
    return Array.from({length: num}, (_, i) => i + 1);
  }

  paginado(number: any) {
    // this.service.findHistorico(this.storage.getUserId(), number - 1, this.size).subscribe({
    //   next: (page) => {
    //     this.dataSource.data = page.content
    //     this.pageNumber = page.pageable.pageNumber
    //     this.totalPage = page.totalPages
    //   }, error: () => {
    //   }
    // });
  }

  search() {

  }

  redirectToDetails(id: any) {
    this.router.navigate(['/historico', id]);
  }
}

