import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Subject, takeUntil } from "rxjs";
import { ComprovanteService } from 'src/app/comprovante/comprovante.service';
import { StorageService } from 'src/app/storage.service';
import { ExcelService } from "../../excel.service";
import { ProdutorService } from "../../produtor/produtor.service";
import { PropriedadeService } from "../../produtor/propriedade.service";
import { ViaCepService } from "../../via-cep.service";
import { LoteService } from '../lote.service';

@Component({
  selector: 'app-lote-detalhes',
  templateUrl: './lote-detalhes.component.html',
  styleUrls: ['./lote-detalhes.component.scss']
})
export class LoteDetalhesComponent implements OnInit, OnDestroy {

  isEditando: boolean = false;
  loteId: any;
  private destroy$ = new Subject<void>();
  msgButton: string;
  form: FormGroup;
  produtores: any[] = [];
  exibirTanque: boolean = false;
  loteDetail: any;
  dataFinalizar: any;
  horaFinalizar: any;
  comprovanteEmAberto: boolean = true;

  constructor(private formBuilder: FormBuilder,
    private service: PropriedadeService,
    private produtorService: ProdutorService,
    private excelService: ExcelService,
    private toastr: ToastrService,
    private viaCepService: ViaCepService,
    private loteService: LoteService,
    public storage: StorageService,
    private route: ActivatedRoute,
    private comprovante: ComprovanteService,
    private router: Router) {
    this.form = this.formBuilder.group({
      id: [''],
      nomeTanque: [''],
      lote: [''],
      ciclo: [''],
      tipoTanque: [''],
      area: [''],
      potenciaAeracaoTotal: [''],
      especie: [''],
      origem: [''],
      dataAlojamento: [''],
      qtdRecebida: [''],
      mortalidade: [''],
      qtdRecebida2: [''],
      pesoMedio: [''],
      biomassaTotal: [''],
      densidade: [''],
      biomassaCVAtual: [''],
      pesoAbateEsperado: [''],
      biomassaEstimadaFinal: [''],
      data: [''],
      saida: [''],
      entrada: [''],
      temperaturaAgua: [''],
      oxigenio: [''],
      placaVeiculo: [''],
      numeroCaixas: [''],
      peixePorCaixa: [''],
      pesoMedioComprovante: [''],
      pesoTotal: [''],
      numeroLacre: [''],
    });
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.loteId = params.get('id');
      this.getLoteDetail();
      this.getLoteInfos();

    });

    this.produtorService.getProdutorReduce().subscribe(data => {
      this.produtores = data;
    });

    this.comprovante.emAberto(this.loteId).subscribe(data => {
      this.comprovanteEmAberto = data;
    });
  }


ngOnDestroy() {
  this.destroy$.next();
  this.destroy$.complete();
}

  getLoteDetail(): void {
    this.loteService.findDetail(parseInt(this.loteId)).subscribe(data => {
      this.loteDetail = data;
    });
  }

  getLoteInfos(): void {
    this.loteService.findInfos(parseInt(this.loteId)).subscribe(data => {
      this.form.patchValue(data);
    });
  }

  onSubmit() {
    this.service.save(this.form.getRawValue()).pipe(takeUntil(this.destroy$)
    ).subscribe({
      next: response => {
        this.toastr.success('Formulário salvo com sucesso!');
        this.router.navigate(['/inicio']);
      },
      error: error => {
        console.error('Erro:', error);
      }
    });
  }

  popUpFinalizar() {
    this.openPopup();
  }

  aceitarLote() {
    this.comprovante.validarLote(this.loteId).subscribe(data => {
      console.log("lote aceito");
      localStorage.setItem('loteAceito', this.loteId.toString());
      this.router.navigate(['/inicio']);
    });
  }

  recusarLote() {
    this.comprovante.updateRecusarLote(this.loteId, true).subscribe(data => {
      console.log("lote recusado");
      this.router.navigate(['/inicio']);
    });

  }

  finalizarLote(dataFinalizar, horaFinalizar) {
    const loteFinalizacao = {
      loteId: parseInt(this.loteId),
      dtFinalizacao: dataFinalizar,
      hrFinalizacao: horaFinalizar
    };

    this.loteService.patch(loteFinalizacao).pipe(takeUntil(this.destroy$)
    ).subscribe({
      next: response => {
        this.toastr.success('Lote finalizado com sucesso!');
        this.closePopup();
      },
      error: error => {
        console.error('Erro:', error);
      }
    });
  }


  getEnderecoViaCep() {
    this.viaCepService.getEndereco(this.form.get('endereco.cep').value).pipe(takeUntil(this.destroy$)
    ).subscribe({
      next: response => {
        this.form.get('endereco').patchValue(response);
      },
      error: error => {
        console.error('Erro:', error);
      }
    });
  }

  selectOpen() {
    document.querySelector('.select-title').classList.toggle('select-title-open');
    document.querySelector('.menu-arrow-icon-select').classList.toggle('menu-arrow-icon-select-open');

    document.querySelector('.select-infos').classList.toggle('select-infos-open');
  }

  enableEdit(inputId: string): void {
    const allInputs = document.querySelectorAll('.form-control');
    const editingInput = document.getElementById(inputId);

    allInputs.forEach(input => {
      if (input !== editingInput) {
        input.classList.add('click-disabled');
        input.classList.remove('focus-editing-input');
      }
    });

    editingInput.classList.toggle('click-disabled');
    editingInput.classList.toggle('focus-editing-input');
  }

  onDownload(): void {
    this.excelService.downloadExcel().subscribe(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'data.xlsx';
      a.click();
      window.URL.revokeObjectURL(url);
    }, error => {
      console.error('Download failed', error);
    });
  }

 cleanTextArea() {
    const textArea = document.getElementById('observacaoLoteText') as HTMLTextAreaElement;
    textArea.value = '';
  }

  popUpRecusar() {
    var overlay = document.getElementById('overlayValidationLote');
    overlay.style.display = 'block';

    var popupWall = document.getElementById('popupWall');
    popupWall.style.display = 'block';
  }

  closePopUpRecusar() {
    var overlay = document.getElementById('overlayValidationLote');
    overlay.style.display = 'none';

    var popupWall = document.getElementById('popupWall');
    popupWall.style.display = 'none';
  }


  openPopup() {
    var overlay = document.getElementById('overlayEndLote');
    overlay.style.display = 'block';

    var popupWall = document.getElementById('popupWall');
    popupWall.style.display = 'block';
  }

  closePopup() {
    var overlay = document.getElementById('overlayEndLote');
    overlay.style.display = 'none';

    var popupWall = document.getElementById('popupWall');
    popupWall.style.display = 'none';
  }

}
