<div>

  <div class="button-close-div">
    <mat-icon class="icon-close material-symbols-rounded" (click)="fecharQuadrado()">close</mat-icon>
  </div>

  <div class="container-header">
    <h4>Filtragem</h4>
    <h4 id="text-limpar" (click)="limparDados()">Limpar todos</h4>
  </div>

  <mat-divider></mat-divider>

  <div class="container-sub-header">
    <h4 id="text-filtro">Filtros aplicados</h4>
    <h4 *ngIf="nenhumSelecionado">Nenhum selecionado</h4>
  </div>
</div>

<div class="container-chips">

  <mat-chip-set>
    <mat-chip *ngFor="let chip of checkedCidades">
      <span class="filter-type">Local</span>
      {{ chip }}
      <mat-icon class="icon-filter-remove material-symbols-rounded" (click)="toggleCidade(chip)" (click)="desativarCheckbox(chip)">close</mat-icon>
    </mat-chip>
  </mat-chip-set>
</div>

<div class="container-options">
  <mat-accordion multi>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Local
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="checkbox-container">
        <mat-checkbox class="checkbox-item" *ngFor="let cidade of cidades; let i = index" (change)="toggleCidade(cidade)" [(ngModel)]="checkboxesAtivos[cidade]">
          {{ cidade }}
        </mat-checkbox>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>


<div id="container-footer">
  <button class="button-geral-full" type="button" (click)="filter()" [disabled]="nenhumSelecionado" [ngClass]="{'button-disabled': nenhumSelecionado}">Ver resultados</button>
</div>
