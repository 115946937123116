import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { EmpresaService } from "../empresa/empresa.service";
import { StorageService } from "../storage.service";
import { Subject, takeUntil } from "rxjs";
import { tap } from 'rxjs/operators';
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute, Router } from "@angular/router";
import { StringNumberFormats } from "../utils/StringNumberFormats";
import { provideEcharts } from 'ngx-echarts';
import { EChartsOption } from 'echarts';
import { DadosApiTemporariaService } from '../dados-api-temporaria.service';

@Component({
  selector: 'app-dashboards',
  templateUrl: './dashboards.component.html',
  styleUrls: ['./dashboards.component.scss'],
  providers: [
    provideEcharts(),
  ]
})
export class DashboardsComponent implements OnInit {

  form: FormGroup;
  showGraphs: boolean;

  popIcon: string;
  popTitle: string;
  popText: string;

  produtores: any[] = [];
  propriedades: any[] = [];
  tanques: any[] = [];
  leiturasTanque: any[] = [];
  leiturasTanqueOxigenio: any[] = [];
  leituraAtualTanque: any[] = [];
  leiturasPopup: any[] = [];

  nomeProdutor: string;
  nomePropriedade: string;
  nomeTanque: string;

  ph: number[] = [];
  saturacao: number[] = [];
  oxigenio: number[] = [];

  graphType: string;
  graphValue: number[] = [];
  temperaturaPopup: number[] = [];
  saturacaoPopup: number[] = [];
  oxigenioPopup: number[] = [];
  GraphMaxTemperatures: number[] = [];
  GraphMinTemperatures: number[] = [];


  aeradoresAtivos: number;
  aeradoresInativos: number;
  currentPh: string;
  currentTemperatura: string;
  temperaturaMax: string;
  temperaturaMin: string;
  currentSaturacao: string;
  currentOxigenio: string;
  lastHour: string;

  selectedProdutor: string;
  selectedPropriedade: string;
  selectedTanque: string;

  selectedDateOption: string = 'Dia';

  // Agora as opções de gráficos são do tipo EChartsOption
  chartOptionAerador: EChartsOption = {};
  chartOptionPH: EChartsOption = {};
  chartOptionTemperatura: EChartsOption = {};
  chartOptionSaturacao: EChartsOption = {};
  chartOptionOxigenio: EChartsOption = {};
  chartOption: EChartsOption = {};

  // Dados de exemplo
  labelsHours: string[] = []; // Labels de tempo das últimas 6 horas do dia
  labelsForPopup: string[] = []; // Labels de tempo do dia todo
  labelsHoursOxygen: string[] = []; // Labels de tempo de 30 em 30 minutos
  labelsDays: string[] = ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5', 'Day 6']; // Labels de tempo

  pageNumber: number;
  totalPage: number;
  size: number = 5;
  lastPage: boolean = false;

  progressBarWidth: number = 0;
  progressBarNumber: string = '0';


  constructor(
    private formBuilder: FormBuilder,
    public storage: StorageService,
    private apiService: DadosApiTemporariaService) {
    this.form = this.formBuilder.group({
      nomeProdutor: ['', Validators.required],
      nomePropriedade: ['', Validators.required],
      nomeTanque: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.getProdutores();
    this.getPropriedades();
  }


  getProdutores(): void {
    this.apiService.getProdutores().subscribe((dados) => {
      this.produtores = dados;
    });
  }

  getPropriedades(): void {
    this.apiService.getPropriedades().subscribe((dados) => {
      this.propriedades = dados;
    });
  }

  getTemperaturaMaxMinDia(): void {
    this.apiService.getTemperaturaMaxMinDia(this.selectedTanque).subscribe((dados) => {
      this.temperaturaMax = dados['temperatura_max'];
      this.temperaturaMin = dados['temperatura_min'];
    });
  }

  // Quando um produtor é selecionado, carrega suas propriedades
  // onProdutorChange(produtorId: string): void {
  //   if (produtorId) {
  //     this.apiService.getPropriedadesPorProdutor(produtorId).subscribe((dados) => {
  //       this.propriedades = dados;
  //       this.tanques = [];
  //       this.form.controls['nomePropriedade'].setValue('');
  //       this.form.controls['nomeTanque'].setValue('');
  //     });
  //   } else {
  //     this.propriedades = [];
  //   }
  // }

  onProdutorChange(produtorId: string): void {
    this.updateProgressBar();
  }


  onPropriedadeChange(propriedadeId: string): void {
    this.updateProgressBar();

    this.tanques = [];

    if (propriedadeId) {
      this.apiService.getTanquesPorPropriedade(propriedadeId).subscribe((dados) => {
        this.tanques = dados;
        this.selectedTanque = '';
      });
    }
  }

  onTanqueChange(tanqueId: string): void {
    this.updateProgressBar();
  }

  onTanqueBoxNameChange() {
    this.onSubmit();
  }


  updateProgressBar(): void {
    let filledCount = 0;

    // Verifica quantos selects foram preenchidos
    if (this.selectedProdutor) filledCount++;
    if (this.selectedPropriedade) filledCount++;
    if (this.selectedTanque) filledCount++;

    // A largura da barra será 33% para cada input preenchido
    this.progressBarWidth = filledCount * 33.3333;
    this.progressBarNumber = String(filledCount);
  }


  getNumberPages(idTanque: string) {
    this.pageNumber = (this.tanques.findIndex(tanque => tanque.id === idTanque)) + 1;
    this.totalPage = this.tanques.length;
  }

  nextOrBack(isAvancar: boolean) {
    this.pageNumber = isAvancar ? this.pageNumber + 1 : this.pageNumber - 1;
    this.selectedTanque = this.tanques[this.pageNumber - 1]['id'];
    this.onSubmit();

  }

  chancePageByNumberClick(numberPageClick: number) {
    this.selectedTanque = this.tanques[numberPageClick - 1]['id'];
    this.onSubmit();
  }

  getSequence(num: number): number[] {
    return Array.from({ length: num }, (_, i) => i + 1);
  }

  paginado(number: number) {
    // this.service.page(number - 1, this.size, '', []).subscribe({
    //   next: (page) => {
    //     this.dataSource.data = page.content
    //     this.pageNumber = page.pageable.pageNumber
    //     this.totalPage = page.totalPages
    //     this.lastPage = page.last
    //   }, error: () => {
    //   }
    // });
  }

  onSubmit() {
    this.apiService.getLeiturasTanque(this.selectedTanque, 'all', 'hora').subscribe((dados) => {
      this.leiturasTanqueOxigenio = [];
      this.leiturasTanqueOxigenio = dados;

      this.getTimeOxygen();
      this.getOxigenio();
    });

    this.apiService.getLeiturasTanque(this.selectedTanque, 'all', 'dia').subscribe((dados) => {
      this.leiturasTanque = [];
      this.leiturasTanque = dados;
      this.leituraAtualTanque = dados[dados.length - 1];

      this.settarInfos();

      this.getTemperaturaMaxMinDia();
      this.getTime();
      this.getPH();
      this.getSaturacao();
      this.gerarGraficoAerador();

      this.getNumberPages(this.selectedTanque);

      this.showGraphs = true;
    });

  }

  settarInfos() {
    // Setta dos dados mais recentes
    if (this.leituraAtualTanque) {
      this.aeradoresAtivos = this.leituraAtualTanque['aeradores'];
      this.currentTemperatura = this.leituraAtualTanque['temperatura_max'];
      this.lastHour = this.leituraAtualTanque['hora'] + ':00';

    } else {
      alert('Esse tanque não possui registros ou não não há registros novos há muito tempo.')
      this.aeradoresAtivos = 0;
      this.temperaturaMax = '0';
      this.temperaturaMin = '0';
      this.lastHour = 'desconhecida';
    }

    this.nomeProdutor = this.produtores.find(p => p.id === this.selectedProdutor)['nome'];
    this.nomePropriedade = this.propriedades.find(p => p.id === this.selectedPropriedade)['nome'];
    this.nomeTanque = this.tanques.find(t => t.id === this.selectedTanque)['nome'];
    this.aeradoresInativos = this.tanques.find(t => t.id === this.selectedTanque)['aeradores'] - this.aeradoresAtivos;
  }


  getPH() {
    this.ph = this.leiturasTanque.map((leituras: any) => leituras.ph).slice(-7);

    this.gerarGraficoPH();
  }

  getSaturacao() {
    this.saturacao = this.leiturasTanque.map((leituras: any) => leituras.saturacao).slice(-7);

    this.gerarGraficoSaturacao();
  }

  getOxigenio() {
    this.oxigenio = this.leiturasTanqueOxigenio.map((leituras: any) => leituras.oxigenio).slice(-14);

    this.gerarGraficoOxigenio();
  }

  getTime() {
    this.labelsHours = this.leiturasTanque.map((leituras: any) => {
      const hour = leituras.hora;
      return hour.toString().padStart(2, '0') + ":00";
    }).slice(-7);
  }

  getTimeOxygen() {
    this.labelsHoursOxygen = this.leiturasTanqueOxigenio.map((leituras: any) => {
      const hour = leituras.hora;
      const minute = leituras.minuto;
      return hour.toString().padStart(2, '0') + ":" + minute.toString().padStart(2, '0');
    }).slice(-14);

    console.log(this.labelsHoursOxygen)
  }


  // ------------------------------------------------------------------------------------------------------------------------------------------------------


  gerarGraficoAerador(): void {
    this.chartOptionAerador = {
      tooltip: {
        trigger: 'item', // Exibe o tooltip quando passar o mouse sobre o item
        formatter: '{b}', // Formatação do tooltip, onde {b} é o nome, {c} é o valor e {d} é a porcentagem
        backgroundColor: '#18181B', // Cor de fundo do tooltip
        borderColor: '#ccc', // Cor da borda do tooltip
        borderWidth: 1, // Largura da borda
        textStyle: {
          color: '#FFF', // Cor do texto
          fontSize: 14, // Tamanho da fonte
          fontWeight: 600,
        },
      },
      legend: {
        show: true, // Ativa a exibição da legenda
        orient: 'horizontal', // Define a orientação da legenda (pode ser 'horizontal' ou 'vertical')
        bottom: '10px',
        left: 'center', // Define a posição da legenda (no lado direito)
        data: [`${this.aeradoresAtivos} ativos`, `${this.aeradoresInativos} inativos`], // Define os itens da legenda (nomes que aparecem)
        textStyle: {
          color: '#333', // Cor do texto da legenda
          fontSize: 14, // Tamanho da fonte da legenda
        },
        itemWidth: 14, // Largura do item da legenda (o retângulo)
        itemHeight: 14, // Altura do item da legenda (o retângulo)
        itemGap: 100,  // Aumenta a distância entre os itens da legenda
      },
      series: [
        {
          name: 'Aeradores',
          type: 'pie',
          radius: ['40%', '70%'], // Cria o gráfico de donut, o primeiro valor é o raio interno e o segundo é o raio externo
          avoidLabelOverlap: false, // Impede que os rótulos sobreponham
          label: {
            show: false,
            formatter: '',
          },
          labelLine: {
            show: true, // Exibe as linhas dos rótulos
          },
          data: [
            { value: this.aeradoresInativos, name: `${this.aeradoresInativos} inativos`, itemStyle: { color: '#165BAA' } },
            { value: this.aeradoresAtivos, name: `${this.aeradoresAtivos} ativos`, itemStyle: { color: '#63ABFD' } },
          ],
        },
      ],
    };
  }


  gerarGraficoPH(): void {
    this.chartOptionPH = {
      grid: {
        show: true, // Exibe a área do grid
        borderColor: '#ECECEC', // Cor das bordas do grid
        borderWidth: 1, // Largura das bordas do grid
      },
      tooltip: {
        trigger: 'item', // 'item' vai ativar o tooltip quando passar o mouse sobre um ponto
        formatter: '{c}', // Formatação do conteúdo do tooltip, {b} é o nome da categoria e {c} é o valor
        backgroundColor: '#18181B', // Cor de fundo do tooltip
        borderColor: '#ccc', // Cor da borda do tooltip
        borderWidth: 1, // Largura da borda do tooltip
        textStyle: {
          color: '#FFF', // Cor do texto dentro do tooltip
          fontSize: 14, // Tamanho da fonte
          fontWeight: 600,
        }
      },
      xAxis: {
        type: 'category',
        data: this.labelsHours,
        axisLabel: {
          rotate: 0,
          interval: 0
        },
        axisTick: {
          interval: 0
        },
        boundaryGap: false,
        splitLine: {
          show: true, // Exibe as linhas de grid verticais
          lineStyle: {
            color: '#ECECEC',
            type: 'solid',
          }
        }
      },
      yAxis: {
        type: 'value',
        name: 'PH',
        splitLine: {
          show: true, // Exibe as linhas de grid horizontais
          lineStyle: {
            color: '#ECECEC',
            type: 'solid',
          }
        },
      },
      series: [
        {
          name: 'PH',
          type: 'line',
          data: this.ph,
          smooth: false,
          step: 'end',  // Isso fará a linha seguir o estilo de grid (escada)
          lineStyle: {
            width: 2, // Largura da linha
            color: '#165BAA',
          },
          symbol: 'circle', // Exibe círculos nos pontos de dados
          symbolSize: 6,
          itemStyle: {
            color: '#165BAA', // Cor das bolinhas (aqui estou usando tomate)
          },
        }
      ]
    };
  }


  gerarGraficoSaturacao(): void {
    this.chartOptionSaturacao = {
      grid: {
        show: true, // Exibe a área do grid
        borderColor: '#ECECEC', // Cor das bordas do grid
        borderWidth: 1, // Largura das bordas do grid
      },
      tooltip: {
        trigger: 'item', // 'item' vai ativar o tooltip quando passar o mouse sobre um ponto
        formatter: '{c}', // Formatação do conteúdo do tooltip, {b} é o nome da categoria e {c} é o valor
        backgroundColor: '#18181B', // Cor de fundo do tooltip
        borderColor: '#ccc', // Cor da borda do tooltip
        borderWidth: 1, // Largura da borda do tooltip
        textStyle: {
          color: '#FFF', // Cor do texto dentro do tooltip
          fontSize: 14, // Tamanho da fonte
          fontWeight: 600,
        }
      },
      xAxis: {
        type: 'category', // Eixo X com categorias
        data: this.labelsHours, // Certifique-se de que this.labels tem o número correto de itens
        axisLabel: {
          rotate: 0,
          interval: 0, // Exibe todas as labels
        },
        axisTick: {
          interval: 0, // Marca todas as linhas de tick
        },
        boundaryGap: false, // Garantir que a linha comece no começo do gráfico
        splitLine: {
          show: true, // Exibe as linhas de grid verticais
          lineStyle: {
            color: '#ECECEC',
            type: 'solid',
          }
        }
      },
      yAxis: {
        type: 'value',
        name: 'Saturação',
        splitLine: {
          show: true, // Exibe as linhas de grid horizontais
          lineStyle: {
            color: '#ECECEC',
            type: 'solid',
          }
        },
      },
      series: [
        {
          name: 'Saturação',
          type: 'line', // Tipo de gráfico 'line'
          data: this.saturacao,
          smooth: false, // Linha reta
          lineStyle: {
            width: 2, // Largura da linha
            color: '#165BAA',
          },
          symbol: 'circle', // Exibe círculos nos pontos de dados
          symbolSize: 6, // Tamanho dos círculos
          itemStyle: {
            color: '#165BAA', // Cor das bolinhas (aqui estou usando tomate)
          },
          step: false
        }
      ]
    };
  }

  gerarGraficoOxigenio(): void {
    this.chartOptionOxigenio = {
      grid: {
        show: true, // Exibe a área do grid
        borderColor: '#ECECEC', // Cor das bordas do grid
        borderWidth: 1, // Largura das bordas do grid
      },
      tooltip: {
        trigger: 'item', // 'item' vai ativar o tooltip quando passar o mouse sobre um ponto
        formatter: '{c}', // Formatação do conteúdo do tooltip, {b} é o nome da categoria e {c} é o valor
        backgroundColor: '#18181B', // Cor de fundo do tooltip
        borderColor: '#ccc', // Cor da borda do tooltip
        borderWidth: 1, // Largura da borda do tooltip
        textStyle: {
          color: '#FFF', // Cor do texto dentro do tooltip
          fontSize: 14, // Tamanho da fonte
          fontWeight: 600,
        }
      },
      xAxis: {
        type: 'category', // Eixo X com categorias
        data: this.labelsHoursOxygen, // Certifique-se de que this.labels tem o número correto de itens
        axisLabel: {
          rotate: 0,
          interval: 0, // Exibe todas as labels
        },
        axisTick: {
          interval: 0, // Marca todas as linhas de tick
        },
        boundaryGap: false, // Garantir que a linha comece no começo do gráfico
        splitLine: {
          show: true, // Exibe as linhas de grid verticais
          lineStyle: {
            color: '#ECECEC',
            type: 'solid',
          }
        }
      },
      yAxis: {
        type: 'value',
        name: 'Oxigênio',
        splitLine: {
          show: true, // Exibe as linhas de grid horizontais
          lineStyle: {
            color: '#ECECEC',
            type: 'solid',
          }
        },
      },
      series: [
        {
          name: 'Oxigênio',
          type: 'line', // Tipo de gráfico 'line'
          data: this.oxigenio,
          smooth: false, // Linha reta
          lineStyle: {
            width: 2, // Largura da linha
            color: '#E697FF',
          },
          symbol: 'circle', // Exibe círculos nos pontos de dados
          symbolSize: 6, // Tamanho dos círculos
          itemStyle: {
            color: '#E697FF ', // Cor das bolinhas (aqui estou usando tomate)
          },
          step: false
        }
      ]
    };
  }


  cleanInput() {
    const inputsBox = document.querySelectorAll('.form-control') as NodeListOf<HTMLInputElement>;
    const selectsBox = document.querySelectorAll('.form-select') as NodeListOf<HTMLSelectElement>;

    selectsBox.forEach(select => {
      select.selectedIndex = -1;
    });

    this.selectedProdutor = null;
    this.selectedPropriedade = null;
    this.selectedTanque = null;

    this.updateProgressBar();
    this.showGraphs = false;
  }


  // Graficos de Pop-up
  // ----------------------------------------------------------------------------------------------------------------------------------------------------
  openGraph(graphType: string) {
    this.chosenGraph(graphType);

    var overlay = document.getElementById('graphPopUp');
    overlay.style.display = 'block';

    var filterWall = document.getElementById('filterWall');
    filterWall.style.display = 'block';
  }

  closeConfirm() {
    var overlay = document.getElementById('graphPopUp');
    overlay.style.display = 'none';

    var filterWall = document.getElementById('filterWall');
    filterWall.style.display = 'none';
  }

  chosenGraph(graphType: string) {
    this.graphType = graphType;

    this.getEspecificData().subscribe(() => {

      switch (graphType) {
        case 'aerador':
          this.popTitle = 'Aeradores'
          this.popIcon = 'sprinkler'
          this.popText = 'Quantidade de aeradores ativos e inativos'
          break;

        case 'ph':
          this.popTitle = 'PH da água'
          this.popIcon = 'cadence'
          this.popText = 'Nível de PH da água'
          break;

        case 'temperatura':
          this.popTitle = 'Temperatura da água'
          this.popIcon = 'thermostat'
          this.popText = 'Temperatura máxima e mínima da água'
          break;

        case 'saturação':
          this.popTitle = 'Saturação'
          this.popIcon = 'insert_chart'
          this.popText = 'Nível de saturação'
          break;

        case 'oxigênio':
          this.popTitle = 'Oxigênio'
          this.popIcon = 'bubble_chart'
          this.popText = 'Nível de oxigenação'
          break;
      }
    });
  }


  GerarPopupGraph() {
    switch (this.graphType) {
      case 'aerador':

        break;

      case 'ph':
        this.gerarPopupGraficoPH();
        break;

      case 'temperatura':
        this.gerarPopupGraficoTemperatura();
        break;

      case 'saturação':
        this.gerarPopupGraficoSaturacao();
        break;

      case 'oxigênio':
        this.gerarPopupGraficoOxigenio();
        break;
    }
  }

  selectDateOption(option: string): void {
    this.selectedDateOption = option;
    this.getEspecificData().subscribe(() => { });;
  }


  getEspecificData() {
    const selectedDateOptionFormated = this.selectedDateOption.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();

    return this.apiService.getLeiturasTanque(this.selectedTanque, 'all', selectedDateOptionFormated).pipe(
      tap(dados => {
        this.leiturasPopup = dados;
        // console.log(this.leiturasPopup);

        this.GerarPopupGraph();
      })
    );

  }


  
  getFullData(dataType: string) {
    this.graphValue = this.leiturasPopup.map((leituras: any) => leituras[dataType]).slice();
  }

  getFullTimeDay() {
    this.labelsForPopup = this.leiturasPopup.map((leituras: any) => {
      const hour = leituras.hora;
      return hour.toString().padStart(2, '0') + ":00";
    }).slice();
  }

  getFullMonth() {
    this.labelsForPopup = this.leiturasPopup.map((leituras: any) => leituras.dia).slice();
  }

  getFullYear() {
    this.labelsForPopup = this.leiturasPopup.map((leituras: any) => {
      const data = new Date(2024, leituras.mes - 1, 1);
      const mesAbreviado = data.toLocaleString('pt-BR', { month: 'short' }).toLowerCase();
      return mesAbreviado;
    }).slice();
  }

  getFullDataTemp() {
    this.GraphMaxTemperatures = this.leiturasPopup.map((leituras: any) => leituras.temperatura_max);
    this.GraphMinTemperatures = this.leiturasPopup.map((leituras: any) => leituras.temperatura_min);
  }

  
  gerarPopupGraficoPH(): void {

    switch (this.selectedDateOption) {
      case 'Dia':
        this.getFullTimeDay();
        this.getFullData('ph');
        break;

      case 'Semana':
        // this.
        break;

      case 'Mês':
        this.getFullMonth();
        this.getFullData('ph');
        break;

      case 'Ano':
        this.getFullYear();
        this.getFullData('ph');
        break;
    }

    this.chartOption = {
      grid: {
        show: true, // Exibe a área do grid
        borderColor: '#ECECEC', // Cor das bordas do grid
        borderWidth: 1, // Largura das bordas do grid
      },
      tooltip: {
        trigger: 'item', // 'item' vai ativar o tooltip quando passar o mouse sobre um ponto
        formatter: '{c}', // Formatação do conteúdo do tooltip, {b} é o nome da categoria e {c} é o valor
        backgroundColor: '#18181B', // Cor de fundo do tooltip
        borderColor: '#ccc', // Cor da borda do tooltip
        borderWidth: 1, // Largura da borda do tooltip
        textStyle: {
          color: '#FFF', // Cor do texto dentro do tooltip
          fontSize: 14, // Tamanho da fonte
          fontWeight: 600,
        }
      },
      xAxis: {
        type: 'category',
        data: this.labelsForPopup,
        axisLabel: {
          rotate: 0,
          interval: 0
        },
        axisTick: {
          interval: 0
        },
        boundaryGap: false,
        splitLine: {
          show: true, // Exibe as linhas de grid verticais
          lineStyle: {
            color: '#ECECEC',
            type: 'solid',
          }
        }
      },
      yAxis: {
        type: 'value',
        name: 'PH',
        splitLine: {
          show: true, // Exibe as linhas de grid horizontais
          lineStyle: {
            color: '#ECECEC',
            type: 'solid',
          }
        },
      },
      series: [
        {
          name: 'PH',
          type: 'line',
          data: this.graphValue,
          smooth: false,
          step: 'end',  // Isso fará a linha seguir o estilo de grid (escada)
          lineStyle: {
            width: 2, // Largura da linha
            color: '#165BAA',
          },
          symbol: 'circle', // Exibe círculos nos pontos de dados
          symbolSize: 6,
          itemStyle: {
            color: '#165BAA', // Cor das bolinhas (aqui estou usando tomate)
          },
        }
      ]
    };
  }

  gerarPopupGraficoTemperatura(): void {

    switch (this.selectedDateOption) {
      case 'Dia':
        this.getFullTimeDay();
        this.getFullDataTemp();
        break;

      case 'Semana':
        // this.
        break;

      case 'Mês':
        this.getFullMonth();
        this.getFullDataTemp();
        break;

      case 'Ano':
        this.getFullYear();
        this.getFullDataTemp();
        break;
    }

    this.chartOption = {
      grid: {
        show: true,
        borderColor: '#ECECEC',
        borderWidth: 1,
      },
      tooltip: {
        trigger: 'item',
        formatter: '{c}°', // Exibe nome da categoria (data) e valor da barra
        backgroundColor: '#18181B',
        borderColor: '#ccc',
        borderWidth: 1,
        textStyle: {
          color: '#FFF',
          fontSize: 14,
          fontWeight: 600,
        }
      },
      xAxis: {
        type: 'category',
        data: this.labelsForPopup, // Certifique-se de que essa variável tem as datas ou categorias
        axisLabel: {
          rotate: 0,
          interval: 0,
        },
        axisTick: {
          interval: 0,
        },
        boundaryGap: true,
        splitLine: {
          show: true,
          lineStyle: {
            color: '#ECECEC',
            type: 'solid',
          }
        }
      },
      yAxis: {
        type: 'value',
        name: 'Temperatura',
        splitLine: {
          show: true,
          lineStyle: {
            color: '#ECECEC',
            type: 'solid',
          }
        },
      },
      series: [
        {
          name: 'Temperatura Mínima',
          type: 'bar',
          data: this.GraphMinTemperatures, // A variável com os valores de temperatura mínima
          barWidth: '40%', // Largura das barras
          itemStyle: {
            color: '#E5E5EF', // Cor da barra de temperatura mínima
          },
          barGap: '-100%', // Faz com que as barras fiquem sobrepostas
          z: 1, // A série de temperatura mínima vai para frente
        },
        {
          name: 'Temperatura Máxima',
          type: 'bar',
          data: this.GraphMaxTemperatures, // A variável com os valores de temperatura máxima
          barWidth: '40%', // Largura das barras
          itemStyle: {
            color: '#165BAA', // Cor da barra de temperatura máxima
          },
          barGap: '-100%', // Faz com que as barras fiquem sobrepostas
          z: 0, // A série de temperatura máxima fica atrás
        }
      ]
    };
    
  }


  gerarPopupGraficoSaturacao(): void {

    switch (this.selectedDateOption) {
      case 'Dia':
        this.getFullTimeDay();
        this.getFullData('saturacao');
        break;

      case 'Semana':
        // this.
        break;

      case 'Mês':
        this.getFullMonth();
        this.getFullData('saturacao');
        break;

      case 'Ano':
        this.getFullYear();
        this.getFullData('saturacao');
        break;
    }

    this.chartOption = {
      grid: {
        show: true, // Exibe a área do grid
        borderColor: '#ECECEC', // Cor das bordas do grid
        borderWidth: 1, // Largura das bordas do grid
      },
      tooltip: {
        trigger: 'item', // 'item' vai ativar o tooltip quando passar o mouse sobre um ponto
        formatter: '{c}', // Formatação do conteúdo do tooltip, {b} é o nome da categoria e {c} é o valor
        backgroundColor: '#18181B', // Cor de fundo do tooltip
        borderColor: '#ccc', // Cor da borda do tooltip
        borderWidth: 1, // Largura da borda do tooltip
        textStyle: {
          color: '#FFF', // Cor do texto dentro do tooltip
          fontSize: 14, // Tamanho da fonte
          fontWeight: 600,
        }
      },
      xAxis: {
        type: 'category', // Eixo X com categorias
        data: this.labelsForPopup, // Certifique-se de que this.labels tem o número correto de itens
        axisLabel: {
          rotate: 0,
          interval: 0, // Exibe todas as labels
        },
        axisTick: {
          interval: 0, // Marca todas as linhas de tick
        },
        boundaryGap: false, // Garantir que a linha comece no começo do gráfico
        splitLine: {
          show: true, // Exibe as linhas de grid verticais
          lineStyle: {
            color: '#ECECEC',
            type: 'solid',
          }
        }
      },
      yAxis: {
        type: 'value',
        name: 'Saturação',
        splitLine: {
          show: true, // Exibe as linhas de grid horizontais
          lineStyle: {
            color: '#ECECEC',
            type: 'solid',
          }
        },
      },
      series: [
        {
          name: 'Saturação',
          type: 'line', // Tipo de gráfico 'line'
          data: this.graphValue,
          smooth: false, // Linha reta
          lineStyle: {
            width: 2, // Largura da linha
            color: '#165BAA',
          },
          symbol: 'circle', // Exibe círculos nos pontos de dados
          symbolSize: 6, // Tamanho dos círculos
          itemStyle: {
            color: '#165BAA', // Cor das bolinhas (aqui estou usando tomate)
          },
          step: false
        }
      ]
    };
  }


  gerarPopupGraficoOxigenio(): void {

    switch (this.selectedDateOption) {
      case 'Dia':
        this.getFullTimeDay();
        this.getFullData('oxigenio');
        break;

      case 'Semana':
        // this.
        break;

      case 'Mês':
        this.getFullMonth();
        this.getFullData('oxigenio');
        break;

      case 'Ano':
        this.getFullYear();
        this.getFullData('oxigenio');
        break;
    }

    this.chartOption = {
      grid: {
        show: true, // Exibe a área do grid
        borderColor: '#ECECEC', // Cor das bordas do grid
        borderWidth: 1, // Largura das bordas do grid
      },
      tooltip: {
        trigger: 'item', // 'item' vai ativar o tooltip quando passar o mouse sobre um ponto
        formatter: '{c}', // Formatação do conteúdo do tooltip, {b} é o nome da categoria e {c} é o valor
        backgroundColor: '#18181B', // Cor de fundo do tooltip
        borderColor: '#ccc', // Cor da borda do tooltip
        borderWidth: 1, // Largura da borda do tooltip
        textStyle: {
          color: '#FFF', // Cor do texto dentro do tooltip
          fontSize: 14, // Tamanho da fonte
          fontWeight: 600,
        }
      },
      xAxis: {
        type: 'category', // Eixo X com categorias
        data: this.labelsForPopup, // Certifique-se de que this.labels tem o número correto de itens
        axisLabel: {
          rotate: 0,
          interval: 0, // Exibe todas as labels
        },
        axisTick: {
          interval: 0, // Marca todas as linhas de tick
        },
        boundaryGap: false, // Garantir que a linha comece no começo do gráfico
        splitLine: {
          show: true, // Exibe as linhas de grid verticais
          lineStyle: {
            color: '#ECECEC',
            type: 'solid',
          }
        }
      },
      yAxis: {
        type: 'value',
        name: 'Oxigênio',
        splitLine: {
          show: true, // Exibe as linhas de grid horizontais
          lineStyle: {
            color: '#ECECEC',
            type: 'solid',
          }
        },
      },
      series: [
        {
          name: 'Oxigênio',
          type: 'line', // Tipo de gráfico 'line'
          data: this.graphValue,
          smooth: false, // Linha reta
          lineStyle: {
            width: 2, // Largura da linha
            color: '#E697FF',
          },
          symbol: 'circle', // Exibe círculos nos pontos de dados
          symbolSize: 6, // Tamanho dos círculos
          itemStyle: {
            color: '#E697FF ', // Cor das bolinhas (aqui estou usando tomate)
          },
          step: false
        }
      ]
    };
  }

  // ----------------------------------------------------------------------------------------------------------------------------------------------------
}