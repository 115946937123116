
<div class="overlay-header">
  <span>Atenção!</span>
  <mat-icon class="icon-close material-symbols-rounded" (click)="closeConfirm()">close</mat-icon>
</div>
<div class="overlay-content">
  <span class="overlay-text">Alterar texto via TS</span>
  <div class="overlay-buttons">
    <button class="confirm-button" (click)="onSubmit()">Alterar texto via TS</button>
    <button class="cancel-button button-white" style="margin-right: 10px" (click)="closeConfirm()">Alterar texto via TS</button>
  </div>
</div>