<div class="container-filter">
  <div class="input-search-box">
    <input class="input-search" type="text" placeholder="Buscar registro?" [(ngModel)]="filter">
    <mat-icon class="icon-search material-symbols-rounded" (click)="search()">search</mat-icon>
  </div>
</div>

<mat-tab-group animationDuration="0ms">
  <mat-tab label="Água">
    <ng-template mat-tab-label>
      <mat-icon class="icon-tabs material-symbols-rounded">water</mat-icon>
      <span>Água</span>
    </ng-template>
    <div class="tab-container">
      <div class="mat-elevation-z2">

        <table mat-table [dataSource]="dataSourceAgua">
          <ng-container matColumnDef="data">
            <th mat-header-cell class="data-column" *matHeaderCellDef id="local">Data</th>
            <td mat-cell class="data-column" *matCellDef="let element">{{element.dtMedicao | dateFormat}} </td>
          </ng-container>

          <ng-container matColumnDef="hora">
            <th mat-header-cell class="hora-column" *matHeaderCellDef id="local">Hora</th>
            <td mat-cell class="hora-column" *matCellDef="let element">{{ element.hora }}</td>
          </ng-container>

          <ng-container matColumnDef="ph">
            <th mat-header-cell class="ph-column" *matHeaderCellDef id="local">pH</th>
            <td mat-cell class="ph-column" *matCellDef="let element">{{ element.ph }}</td>
          </ng-container>

          <ng-container matColumnDef="amonia">
            <th mat-header-cell class="amonia-column" *matHeaderCellDef id="local">Amônia (NH3)</th>
            <td mat-cell class="amonia-column" *matCellDef="let element">{{ element.ph }}</td>
          </ng-container>

          <ng-container matColumnDef="nitrito">
            <th mat-header-cell class="nitrito-column" *matHeaderCellDef id="local">Nitrito (NO2)</th>
            <td mat-cell class="nitrito-column" *matCellDef="let element">{{ element.nitrito }}</td>
          </ng-container>

          <ng-container matColumnDef="alcalinidade">
            <th mat-header-cell class="alcalinidade-column" *matHeaderCellDef id="local">Alcalinidade</th>
            <td mat-cell class="alcalinidade-column" *matCellDef="let element">{{ element.alcalinidade }}</td>
          </ng-container>

          <ng-container matColumnDef="transparencia">
            <th mat-header-cell class="transparencia-column" *matHeaderCellDef id="local">Transparência (cm)</th>
            <td mat-cell class="transparencia-column" *matCellDef="let element">{{ element.transparencia }}</td>
          </ng-container>

          <ng-container matColumnDef="oxigenio">
            <th mat-header-cell class="oxigenio-column" *matHeaderCellDef id="local">Oxigênio (mg/L)</th>
            <td mat-cell class="oxigenio-column" *matCellDef="let element">{{ element.oxigenio }}</td>
          </ng-container>

          <ng-container matColumnDef="temperatura">
            <th mat-header-cell class="temperatura-column" *matHeaderCellDef id="local">Temperatura (ºC)</th>
            <td mat-cell class="temperatura-column" *matCellDef="let element">{{ element.temperatura }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsAgua"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsAgua;"></tr>
        </table>

        <div id="paginacao" class="flex-align-itens-center">
          <mat-icon class="arrow-table-back material-symbols-rounded" (click)="nextOrBack(false)">outbound</mat-icon>
          <div class="flex-align-itens-center">
            <ng-container *ngFor="let number of getSequence(totalPage)">
              <span id="paginacao-number" (click)="paginado(number)">{{ number }}</span>
            </ng-container>
          </div>
          <mat-icon class="arrow-table-next material-symbols-rounded" (click)="nextOrBack(true)">outbound</mat-icon>
        </div>
      </div>
    </div>
  </mat-tab>

  <mat-tab label="Biometria">
    <ng-template mat-tab-label>
      <mat-icon class="icon-tabs material-symbols-rounded">set_meal</mat-icon>
      <span>Biometria</span>
    </ng-template>
    <div class="tab-container">
      <div class="mat-elevation-z2">

        <table mat-table [dataSource]="dataSourceBiometria">
          <ng-container matColumnDef="dtMedicao">
            <th mat-header-cell class="dtMedicao-column" *matHeaderCellDef id="local">Data</th>
            <td mat-cell class="dtMedicao-column" *matCellDef="let element">{{ element.dtMedicao | dateFormat }}</td>
          </ng-container>

          <ng-container matColumnDef="hora">
            <th mat-header-cell class="hora-column" *matHeaderCellDef id="local">Hora</th>
            <td mat-cell class="hora-column" *matCellDef="let element">{{ element.hora }}</td>
          </ng-container>

          <ng-container matColumnDef="peixePorAmostra">
            <th mat-header-cell class="peixePorAmostra-column" *matHeaderCellDef id="local">Peixe/amostra</th>
            <td mat-cell class="peixePorAmostra-column" *matCellDef="let element">{{ element.peixePorAmostra }}</td>
          </ng-container>

          <ng-container matColumnDef="mortalidade">
            <th mat-header-cell class="mortalidade-column" *matHeaderCellDef id="local">Mortalidade</th>
            <td mat-cell class="mortalidade-column" *matCellDef="let element">{{ element.mortalidade }}</td>
          </ng-container>

          <ng-container matColumnDef="mortalidadeAcumulada">
            <th mat-header-cell class="mortalidadeAcumulada-column" *matHeaderCellDef id="local">Mortalidade acumulada
            </th>
            <td mat-cell class="mortalidadeAcumulada-column" *matCellDef="let element">{{ element.mortalidadeAcumulada }}</td>
          </ng-container>

          <ng-container matColumnDef="qntPeixe">
            <th mat-header-cell class="qntPeixe-column" *matHeaderCellDef id="local">Qtde. Peixes</th>
            <td mat-cell class="qntPeixe-column" *matCellDef="let element">{{ element.qntPeixe }}</td>
          </ng-container>

          <ng-container matColumnDef="pesoMedio">
            <th mat-header-cell class="pesoMedio-column" *matHeaderCellDef id="local">Peso médio</th>
            <td mat-cell class="pesoMedio-column" *matCellDef="let element">{{ element.pesoMedio }}</td>
          </ng-container>

          <ng-container matColumnDef="biomassaTotal">
            <th mat-header-cell class="biomassaTotal-column" *matHeaderCellDef id="local">Biomassa total</th>
            <td mat-cell class="biomassaTotal-column" *matCellDef="let element">{{ element.biomassaTotal }}</td>
          </ng-container>

          <ng-container matColumnDef="ganhoPeso">
            <th mat-header-cell class="ganhoPeso-column" *matHeaderCellDef id="local">Ganho de peso</th>
            <td mat-cell class="ganhoPeso-column" *matCellDef="let element">{{ element.ganhoPeso }}</td>
          </ng-container>

          <ng-container matColumnDef="racaoTotal">
            <th mat-header-cell class="racaoTotal-column" *matHeaderCellDef id="local">Ração total</th>
            <td mat-cell class="racaoTotal-column" *matCellDef="let element">{{ element.racaoTotal }}</td>
          </ng-container>

          <ng-container matColumnDef="ca">
            <th mat-header-cell class="ca-column" *matHeaderCellDef id="local">C.A</th>
            <td mat-cell class="ca-column" *matCellDef="let element">{{ element.ca }}</td>
          </ng-container>

          <ng-container matColumnDef="gpd">
            <th mat-header-cell class="gpd-column" *matHeaderCellDef id="local">GPD</th>
            <td mat-cell class="gpd-column" *matCellDef="let element">{{ element.gpd }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsBiometria"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsBiometria;"></tr>
        </table>

        <div id="paginacao" class="flex-align-itens-center">
          <mat-icon class="arrow-table-back material-symbols-rounded" (click)="nextOrBack(false)">outbound</mat-icon>
          <div class="flex-align-itens-center">
            <ng-container *ngFor="let number of getSequence(totalPage)">
              <span id="paginacao-number" (click)="paginado(number)">{{ number }}</span>
            </ng-container>
          </div>
          <mat-icon class="arrow-table-next material-symbols-rounded" (click)="nextOrBack(true)">outbound</mat-icon>
        </div>
      </div>
    </div>
  </mat-tab>

  <mat-tab label="Trato">
    <ng-template mat-tab-label>
      <mat-icon class="icon-tabs material-symbols-rounded">restaurant</mat-icon>
      <span>Trato</span>
    </ng-template>
    <div class="tab-container">
      <div class="mat-elevation-z2">

        <table mat-table [dataSource]="dataSourceTrato">
          <ng-container matColumnDef="data">
            <th mat-header-cell class="data-column" *matHeaderCellDef id="local">Data</th>
            <td mat-cell class="data-column" *matCellDef="let element">{{ element.dtMedicao }}</td>
          </ng-container>

          <ng-container matColumnDef="hora">
            <th mat-header-cell class="hora-column" *matHeaderCellDef id="local">Hora</th>
            <td mat-cell class="hora-column" *matCellDef="let element">{{ element.hora }}</td>
          </ng-container>

          <ng-container matColumnDef="temperatura">
            <th mat-header-cell class="temperatura-column" *matHeaderCellDef id="local">Temperatura (ºC)</th>
            <td mat-cell class="temperatura-column" *matCellDef="let element">{{ element.temperatura }}</td>
          </ng-container>

          <ng-container matColumnDef="oxigenio">
            <th mat-header-cell class="oxigenio-column" *matHeaderCellDef id="local">Oxigênio (mg/L)</th>
            <td mat-cell class="oxigenio-column" *matCellDef="let element">{{ element.oxigenio }}</td>
          </ng-container>

          <ng-container matColumnDef="racaoOfertada">
            <th mat-header-cell class="racaoOfertada-column" *matHeaderCellDef id="local">Ração ofertada (Kg)</th>
            <td mat-cell class="nitrito-column" *matCellDef="let element">{{ element.racaoOfertada }}</td>
          </ng-container>

          <ng-container matColumnDef="racaoTotalDespejada">
            <th mat-header-cell class="racaoTotalDespejada-column" *matHeaderCellDef id="local">Ração total despejada
              (Kg)</th>
            <td mat-cell class="racaoTotalDespejada-column" *matCellDef="let element">{{ element.racaoTotalDespejada }}</td>
          </ng-container>

          <ng-container matColumnDef="racaoRestanteSilo">
            <th mat-header-cell class="racaoRestanteSilo-column" *matHeaderCellDef id="local">Ração restante no silo
            </th>
            <td mat-cell class="racaoRestanteSilo-column" *matCellDef="let element">{{ element.racaoRestanteSilo }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsTrato"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsTrato;"></tr>
        </table>

        <div id="paginacao" class="flex-align-itens-center">
          <mat-icon class="arrow-table-back material-symbols-rounded" (click)="nextOrBack(false)">outbound</mat-icon>
          <div class="flex-align-itens-center">
            <ng-container *ngFor="let number of getSequence(totalPage)">
              <span id="paginacao-number" (click)="paginado(number)">{{ number }}</span>
            </ng-container>
          </div>
          <mat-icon class="arrow-table-next material-symbols-rounded" (click)="nextOrBack(true)">outbound</mat-icon>
        </div>
      </div>
    </div>
  </mat-tab>

  <mat-tab label="Estoque">
    <ng-template mat-tab-label>
      <mat-icon class="icon-tabs material-symbols-rounded">package_2</mat-icon>
      <span>Estoque</span>
    </ng-template>
    <div class="tab-container">
      <div class="mat-elevation-z2">

        <table mat-table [dataSource]="dataSourceEstoque">
          <ng-container matColumnDef="dtRecebimento">
            <th mat-header-cell class="dtRecebimento-column" *matHeaderCellDef id="local">Data de recebimento</th>
            <td mat-cell class="dtRecebimento-column" *matCellDef="let element">{{ element.dtRecebimento | dateFormat }}</td>
          </ng-container>

          <ng-container matColumnDef="qtdRecebida">
            <th mat-header-cell class="qtdRecebida-column" *matHeaderCellDef id="local">Qtde. recebida (Kg)</th>
            <td mat-cell class="qtdRecebida-column" *matCellDef="let element">{{ element.qtdRecebida }}</td>
          </ng-container>

          <ng-container matColumnDef="tipoRacao">
            <th mat-header-cell class="tipoRacao-column" *matHeaderCellDef id="local">Tipo da ração</th>
            <td mat-cell class="tipoRacao-column" *matCellDef="let element">{{ element.tipoRacao }}</td>
          </ng-container>

          <ng-container matColumnDef="nrmNf">
            <th mat-header-cell class="nrmNf-column" *matHeaderCellDef id="local">Nº da nota</th>
            <td mat-cell class="nrmNf-column" *matCellDef="let element">{{ element.nrmNf }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsEstoque"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsEstoque;"></tr>
        </table>

        <div id="paginacao" class="flex-align-itens-center">
          <mat-icon class="arrow-table-back material-symbols-rounded" (click)="nextOrBack(false)">outbound</mat-icon>
          <div class="flex-align-itens-center">
            <ng-container *ngFor="let number of getSequence(totalPage)">
              <span id="paginacao-number" (click)="paginado(number)">{{ number }}</span>
            </ng-container>
          </div>
          <mat-icon class="arrow-table-next material-symbols-rounded" (click)="nextOrBack(true)">outbound</mat-icon>
        </div>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>