import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Subject, takeUntil } from "rxjs";
import { LoteService } from './lote.service';


@Component({
  selector: 'app-lote',
  templateUrl: './lote.component.html',
  styleUrls: ['./lote.component.scss']
})
export class LoteComponent implements OnInit, OnDestroy {

  isEditando: boolean = false;
  private destroy$ = new Subject<void>();
  form: FormGroup;
  produtorNome: string;
  propriedade: string;
  tanqueNome: string;
  tipoTanque: string;
  lote: any;
  area: string
  potenciaAeracaoTotal: string

  constructor(private formBuilder: FormBuilder,
    private service: LoteService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router) {
    this.form = this.formBuilder.group({
      id: [],
      tanque: [],
      tanqueId: [],
      especie: ['', Validators.required],
      origem: ['', Validators.required],
      ciclo: [''],
      lote: [''],
      dataAlojamento: ['', Validators.required],
      qtdRecebida: ['', Validators.required],
      mortalidade: ['', Validators.required],
      qtdRecebida2: ['', Validators.required],
      pesoMedio: ['', Validators.required],
      biomassaTotal: ['', Validators.required],
      densidade: ['', Validators.required],
      biomassaCVAtual: ['', Validators.required],
      pesoAbateEsperado: ['', Validators.required],
      biomassaEstimadaFinal: ['', Validators.required],
    });
  }

  ngOnInit() {
    const dados = JSON.parse(localStorage.getItem('infoLote')!);

    this.produtorNome = dados.produtor;
    this.propriedade = dados.propriedade;
    this.tanqueNome = dados.tanqueNome;
    this.tipoTanque = dados.tanque;
    this.area = dados.area;
    this.potenciaAeracaoTotal = dados.potenciaAeracaoTotal;
    this.form.get('tanqueId').setValue(dados.tanqueId)

    this.form.get('qtdRecebida').valueChanges.subscribe(() => {this.calcularBiomassa(); this.calcularDensidade(); this.calcularBiomassaEstimada()});
    this.form.get('qtdRecebida2').valueChanges.subscribe(() => {this.calcularBiomassa(); this.calcularDensidade(); this.calcularBiomassaEstimada()});
    this.form.get('mortalidade').valueChanges.subscribe(() => {this.calcularBiomassa(); this.calcularBiomassaEstimada()});
    this.form.get('pesoMedio').valueChanges.subscribe(() => this.calcularBiomassa());
    this.form.get('biomassaTotal').valueChanges.subscribe(() => this.calcularBiomassaPorCv());
    this.form.get('pesoAbateEsperado').valueChanges.subscribe(() => this.calcularBiomassaEstimada());

    this.service.getMaxLote(this.form.get('tanqueId').value).subscribe(data => {
      this.lote = data;
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onSubmit() {
    this.form.get('lote').setValue(this.lote);

    this.service.save(this.form.getRawValue()).pipe(takeUntil(this.destroy$)
    ).subscribe({
      next: response => {
        console.log("salvo com sucesso")
        localStorage.setItem('lote', response.id);
        this.router.navigate(['/checklist']);

      },
      error: error => {
        console.error('Erro:', error);
      }
    });

  }

  enableEdit(inputId: string): void {
    const allInputs = document.querySelectorAll('.form-control');
    const editingInput = document.getElementById(inputId);

    allInputs.forEach(input => {
      if (input !== editingInput) {
        input.classList.add('click-disabled');
        input.classList.remove('focus-editing-input');
      }
    });

    editingInput.classList.toggle('click-disabled');
    editingInput.classList.toggle('focus-editing-input');
  }

  openPopup() {
    var overlay = document.getElementById('overlaySuccessRegister');
    overlay.style.display = 'block';

    var popupWall = document.getElementById('popupWall');
    popupWall.style.display = 'block';
  }

  closePopup() {
    var overlay = document.getElementById('overlaySuccessRegister');
    overlay.style.display = 'none';

    var popupWall = document.getElementById('popupWall');
    popupWall.style.display = 'none';
  }

  voltarInicial() {
    this.router.navigate(['/inicio']);
    this.closePopup();
  }

  calcularBiomassa() {
    let qtdRecebida = parseFloat(this.form.get('qtdRecebida').value.replace(',', '.'));
    let qtdRecebida2 = parseFloat(this.form.get('qtdRecebida2').value.replace(',', '.'));
    let mortalidade = parseFloat(this.form.get('mortalidade').value.replace(',', '.'));
    let pesoMedio = parseFloat(this.form.get('pesoMedio').value.replace(',', '.'));

    if (!isNaN(qtdRecebida) && !isNaN(qtdRecebida2) && !isNaN(mortalidade) && !isNaN(pesoMedio)) {
      const biomassaTotal = ((qtdRecebida + qtdRecebida2 - mortalidade) * pesoMedio);

      this.form.get('biomassaTotal').setValue(biomassaTotal.toFixed(5).replace(/\.?0+$/, ''));
    } else {
      this.form.get('biomassaTotal').setValue(0);
    }
  }


  calcularDensidade() {
    let qtdRecebida = parseFloat(this.form.get('qtdRecebida').value.replace(',', '.'));
    let qtdRecebida2 = parseFloat(this.form.get('qtdRecebida2').value.replace(',', '.'));
    let area = parseFloat(this.area);

    if (!isNaN(qtdRecebida) && !isNaN(qtdRecebida2) && !isNaN(area)) {
      const densidade = ((qtdRecebida2 + qtdRecebida) / area);

      this.form.get('densidade').setValue(densidade.toFixed(2));
    } else {
      this.form.get('densidade').setValue(0);
    }
  }


  calcularBiomassaPorCv() {
    let biomassaTotal = parseFloat(this.form.get('biomassaTotal').value);
    let potenciaAeracaoTotal = parseFloat(this.potenciaAeracaoTotal);

    if (!isNaN(biomassaTotal) && !isNaN(potenciaAeracaoTotal)) {
    const biomassaCVAtual = (biomassaTotal / potenciaAeracaoTotal);

    this.form.get('biomassaCVAtual').setValue(biomassaCVAtual.toFixed(2));
  } else {
      this.form.get('biomassaCVAtual').setValue(0);
    }
  }


  calcularBiomassaEstimada() {
    let qtdRecebida = parseFloat(this.form.get('qtdRecebida').value.replace(',', '.'));
    let qtdRecebida2 = parseFloat(this.form.get('qtdRecebida2').value.replace(',', '.'));
    let mortalidade = parseFloat(this.form.get('mortalidade').value.replace(',', '.'));
    let pesoAbateEsperado = parseFloat(this.form.get('pesoAbateEsperado').value.replace(',', '.'));
    let potenciaAeracaoTotal = parseFloat(this.potenciaAeracaoTotal);

    if (!isNaN(qtdRecebida) && !isNaN(qtdRecebida2) && !isNaN(mortalidade) && !isNaN(pesoAbateEsperado) && !isNaN(potenciaAeracaoTotal)) {
    const biomassaEstimadaFinal = ((qtdRecebida + qtdRecebida2 - mortalidade) * pesoAbateEsperado) / potenciaAeracaoTotal;

    this.form.get('biomassaEstimadaFinal').setValue(biomassaEstimadaFinal.toFixed(2));
  } else {
      this.form.get('biomassaEstimadaFinal').setValue(0);
    }
  }

  
  cleanInput() {
    const inputsBox = document.querySelectorAll('.form-control') as NodeListOf<HTMLInputElement>;
    inputsBox.forEach(input => {
      input.value = '';
    });
  }

}
