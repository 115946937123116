<ng-container *ngFor="let tanque of tanquesComLoteValidacao">
  <div class="iniciar-lote-container">
    <div class="iniciar-lote-container-buttons-box">
      <span>{{tanque.nome}}</span>

      <div class="right-header-container-buttons-box">
        <span>{{tanque?.propriedade}}</span>
        <!-- <button class="new-tanque-button" (click)="newTanque()">Novo Tanque</button> -->
      </div>
    </div>

    <ng-container *ngIf="waitingTech">
      <div class="waiting-tec-container">
        <div class="clock-animated">
          <div class="clock"></div>
          <div class="big-clock-pointer"></div>
          <div class="small-clock-pointer"></div>
        </div>
        <span>Aguardando o técnico</span>
      </div>
    </ng-container>

    <ng-container *ngIf="!waitingTech">
      <div class="validate-container">
        <mat-icon class="warning-icon material-symbols-rounded">warning</mat-icon>
        <span (click)="redirectToDetails(tanque.loteId)">Valide o cadastro</span>
      </div>
    </ng-container>
  </div>
</ng-container>

<!-- <ng-container *ngIf="waitingTech">
  <div class="iniciar-lote-container">
    <div class="iniciar-lote-container-buttons-box">
      <span>  </span>

      <div class="right-header-container-buttons-box">
        <button class="new-tanque-button" (click)="newTanque()">Novo Tanque</button>
      </div>
    </div>

    <div class="waiting-tec-container">
      <div class="clock-animated">
        <div class="clock"></div>
        <div class="big-clock-pointer"></div>
        <div class="small-clock-pointer"></div>
      </div>
      <span>Aguardando o técnico</span>
    </div>
  </div>
</ng-container> -->

<ng-container *ngIf="tanquesComLoteEmAberto?.length > 0">

    <div class="lote-iniciado-container">
      <div class="lote-iniciado-container-header">
        <select id="tanqueSelecionado" class="select-tanque" [(ngModel)]="selectedTanque" (ngModelChange)="onTanqueChange()">
          <option *ngFor="let tanque of this.tanquesComLoteEmAberto" [value]="tanque.id">
            {{ tanque.nome }}
          </option>
        </select>

        <div>
          <!-- <span class="info-propriedade-lote-iniciado">Psicultura Sol Nascente</span> -->
          <button class="new-tanque-button" (click)="newTanque()">Novo Tanque</button>
        </div>
      </div>

      <div class="divisor-line"></div>

      
      <div class="lote-iniciado-container-footer">
        <span>Lote {{ loteDetail?.id}}</span>
        <div>
          <div class="info-lote-iniciado-container">
            <span class="info-lote-iniciado-title">Data de alojamento</span>
            <span class="info-lote-iniciado">{{ loteDetail?.dataAlojamento | dateFormat}}</span>
          </div>
          <div class="info-lote-iniciado-container">
            <span class="info-lote-iniciado-title">Peso de abate esperado</span>
            <span class="info-lote-iniciado">{{ loteDetail?.pesoAbateEsperado }}</span>
          </div>
          <div class="info-lote-iniciado-container">
            <span class="info-lote-iniciado-title">Remessa total</span>
            <span class="info-lote-iniciado">{{ loteDetail?.qtdRecebida }}</span>
          </div>
          <div class="info-lote-iniciado-container">
            <span class="info-lote-iniciado-title">Espécie</span>
            <span class="info-lote-iniciado">{{ loteDetail?.especie }}</span>
          </div>

          <button class="white-button" (click)="redirectToDetails(loteDetail?.id)">Ver detalhes</button>
        </div>
      </div>

    </div>

  <div class="tanque-infos">
    <div class="header-infos">
      <select id="tanque" [(ngModel)]="selectedTanque">
        <option class="select-placeholder" value="" disabled selected>Selecione um tanque...</option>
        <option *ngFor="let tanque of tanquesComLoteEmAberto" [value]="tanque.id">{{ tanque?.nome }}</option>
      </select>
    </div>

    <div class="container-biometria">
      <app-tabs-biometrias [tanqueId]="selectedTanque"></app-tabs-biometrias>
    </div>
  </div>
</ng-container>

<!-- <ng-container>
  <div style="margin-top: 100px;">
    <h3 class="historic-title">Histórico de registro</h3>
  </div>

  <button type="button" class="button-geral export-button" style="margin-bottom: 10px; background: #000;"
    (click)="onDownload()">Exportar</button>

  <app-table-registro-historico></app-table-registro-historico>
</ng-container> -->