<div class="container-filter">
    <div class="input-search-box">
        <input class="input-search" type="text" placeholder="O que você procura?" [(ngModel)]="filter">
        <mat-icon class="icon-search material-symbols-rounded" (click)="search()">search</mat-icon>
    </div>

    <div class="buttons-box">
        <button type="button" class="button-no-border filter-button" style="padding-left: 12px;"
            (click)="clearSearch()">Limpar pesquisa</button>
        <div class="box-buttons-2">
            <ng-container *ngIf="storage.getRole() != 'TECNICO'">
                <button type="button" class="button-geral" data-bs-toggle="modal" data-bs-target="#overlay"
                    style="margin-right: 10px" (click)="exibirQuadrado()">
                    Novo equipamento
                </button>
            </ng-container>

            <ng-container *ngIf="storage.getRole() == 'TECNICO'">
                <button type="button" class="button-geral" data-bs-toggle="modal" data-bs-target="#overlay"
                    style="margin-right: 10px" (click)="redirectToProdutor()">
                    Cadastrar Produtor
                </button>
            </ng-container>

            <button type="button" class="button-white filter-button" (click)="exibirFiltro()">
                <div class="filters-numbers" *ngIf="filterQtd > 0">{{ filterQtd }}</div>
                Filtrar
                <mat-icon class="icon-filter material-symbols-rounded">filter_list</mat-icon>
            </button>
        </div>
    </div>
</div>

<div class="mat-elevation-z2">
    <table mat-table [dataSource]="dataSource">

        <ng-container matColumnDef="nome">
            <th mat-header-cell class="nome-column" *matHeaderCellDef id="nome">Nome</th>
            <td mat-cell class="nome-column" *matCellDef="let element"> {{ element.nome }}</td>
        </ng-container>

        <ng-container matColumnDef="empresa">
            <th mat-header-cell class="razaoSocial-column" *matHeaderCellDef id="adm">Empresa</th>
            <td mat-cell class="razaoSocial-column" *matCellDef="let element"> {{ element.empresa.razaoSocial }}</td>
        </ng-container>

        <ng-container matColumnDef="propriedade">
            <th mat-header-cell *matHeaderCellDef id="propriedade">Propriedades</th>
            <td mat-cell *matCellDef="let element"> {{ element.propriedades.length }}</td>
        </ng-container>

        <ng-container matColumnDef="equipamento">
            <th mat-header-cell *matHeaderCellDef id="equipamento">Equipamentos</th>
            <td mat-cell *matCellDef="let element"> {{ getTotalEquipamentos(element) }}</td>
        </ng-container>

        <ng-container matColumnDef="contato">
            <th mat-header-cell class="contato-column" *matHeaderCellDef id="contato">Contato</th>
            <td mat-cell class="contato-column" *matCellDef="let element"> {{ element.celular | celular }}</td>
        </ng-container>


        <ng-container matColumnDef="detalhe">
            <th mat-header-cell *matHeaderCellDef id="header-right">Detalhes</th>
            <td mat-cell *matCellDef="let element" id="detalhe">
                <mat-icon class="icon-search-info material-symbols-rounded"
                    (click)="redirectToDetails(element?.id)">search</mat-icon>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <div id="paginacao" class="flex-align-itens-center">
        <mat-icon class="arrow-table-back material-symbols-rounded" (click)="nextOrBack(false)">outbound</mat-icon>
        <div class="flex-align-itens-center">
            <ng-container *ngFor="let number of getSequence(totalPage)">
                <span id="paginacao-number" (click)="paginado(number)">{{ number }}</span>
            </ng-container>
        </div>
        <mat-icon class="arrow-table-next material-symbols-rounded" (click)="nextOrBack(true)">outbound</mat-icon>
    </div>
</div>


<div id="overlay" class="modal overlay-fade" role="dialog">
    <app-equipamento></app-equipamento>
</div>

<div id="overlay-filter">
    <app-produtor-filter (filtroAlterado)="onFiltroAlterado($event)"
        style="position: relative; height: 100%; display: block;"></app-produtor-filter>
</div>

<div id="filter-wall"></div>