import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DadosApiTemporariaService {

  private baseUrl = 'https://corsproxy.io/?https://trevisan-back.vercel.app';

  constructor(private http: HttpClient) { }

  // Listar todos os produtores
  getProdutores(): Observable<any> {
    return this.http.get(`${this.baseUrl}/produtores`);
  }

  // Listar propriedades de um produtor
  getPropriedadesPorProdutor(produtorId: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/produtores/${produtorId}/propriedades`);
  }

  // Listar todas as propriedades
  getPropriedades(): Observable<any> {
    return this.http.get(`${this.baseUrl}/produtores/propriedades`);
  }

  // Listar tanques de uma propriedade
  getTanquesPorPropriedade(tanqueId: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/produtores/propriedades/${tanqueId}/tanques`);
  }

  // Obter leituras de um tanque
  getLeiturasTanque(tanqueId: string, get: string, time: string): Observable<any> {
    const params = new HttpParams().set('get', get).set('time', time);
    return this.http.get(`${this.baseUrl}/produtores/propriedades/tanques-leitura/${tanqueId}?get=${get}&time=${time}`);
  }
    
  // Listar a temperatura máxima e mínima do dia do tanque
  getTemperaturaMaxMinDia(tanqueId: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/produtores/propriedades/tanques-leitura/${tanqueId}/temperatura`);
  }
}
