<div class="container-filter">
  <div class="input-search-box">
    <input class="input-search" type="text" placeholder="Buscar produtor?" [(ngModel)]="razaoSocial">
    <mat-icon class="icon-search material-symbols-rounded">search</mat-icon>
  </div>
<!--  <button type="button" class="button-white" (click)="exibirQuadrado()">Filtrar-->
<!--    <img src="../../../assets/filter.svg" alt="Imagem Redonda" style="padding-left: 10px">-->
<!--  </button>-->
</div>


<div class="mat-elevation-z2">
  <table mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="nome">
      <th mat-header-cell class="nome-column" *matHeaderCellDef id="nome">Nome</th>
      <td mat-cell class="nome-column" *matCellDef="let element"> {{ element?.nome }}</td>
    </ng-container>

    <ng-container matColumnDef="empresa">
      <th mat-header-cell class="razaoSocial-column" *matHeaderCellDef id="adm">Empresa</th>
      <td mat-cell class="razaoSocial-column" *matCellDef="let element"> {{ element?.empresa?.razaoSocial }}</td>
    </ng-container>


    <ng-container matColumnDef="propriedade">
      <th mat-header-cell *matHeaderCellDef id="propriedade">Propriedades</th>
      <td mat-cell *matCellDef="let element"> {{ element.propriedades?.length }}</td>
    </ng-container>

    <ng-container matColumnDef="contato">
      <th mat-header-cell class="contato-column" *matHeaderCellDef id="contato">Contato</th>
      <td mat-cell class="contato-column" *matCellDef="let element"> {{ element?.celular | celular}}</td>
    </ng-container>

    <ng-container matColumnDef="detalhe">
      <th mat-header-cell class="detalhe-column" *matHeaderCellDef id="header-right">Detalhes</th>
      <td mat-cell class="detalhe-column" *matCellDef="let element" id="detalhe">
        <mat-icon class="icon-search-info material-symbols-rounded" (click)="redirectToDetails(element?.id)">search</mat-icon>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <div id="paginacao" class="flex-align-itens-center">
    <mat-icon class="arrow-table-back material-symbols-rounded" (click)="nextOrBack(false)">outbound</mat-icon>
    <div class="flex-align-itens-center">
      <ng-container *ngFor="let number of getSequence(totalPage)">
        <span id="paginacao-number" (click)="paginado(number)">{{ number }}</span>
      </ng-container>
    </div>
    <mat-icon class="arrow-table-next material-symbols-rounded" (click)="nextOrBack(true)">outbound</mat-icon>
  </div>
</div>

