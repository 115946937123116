<div style="position: relative; min-height: 85vh">
  <div class="container-header">
    <a href="/inicio">
      <mat-icon class="icon back-icon material-symbols-rounded">arrow_back</mat-icon>
    </a>
    <span class="back-text">Voltar para a página principal</span>
  </div>

  <div class="container-form">
    <span style="font-size: 12px; color: #A1A1AA">Dados da Empresa</span>
    <form [formGroup]="form">
      <div class="row g-3">
        <div class="col-6">
          <label id="empresa" for="nomeEmpresa" class="form-label required">Nome da Empresa</label>
          <div class="input-group">
            <input type="text" class="form-control" [ngClass]="{'click-disabled': isEditando}" formControlName="razaoSocial" id="nomeEmpresa" aria-describedby="empresaImage">
            <mat-icon class="icon edit-icon material-symbols-rounded" *ngIf="isEditando" (click)="enableEdit('nomeEmpresa')">edit</mat-icon>
          </div>
        </div>
        <div class="col-6">
          <label id="cnpj" for="cnpjCpf" class="form-label required">CNPJ/CPF</label>
          <div class="input-group">
            <input type="text" class="form-control" [ngClass]="{'click-disabled': isEditando}" formControlName="cnpjCpf" id="cnpjCpf" appCnpjCpfMask [maxLength]="18">
            <mat-icon class="icon edit-icon material-symbols-rounded" *ngIf="isEditando" (click)="enableEdit('cnpjCpf')">edit</mat-icon>
          </div>
        </div>
      </div>

      <div class="row g-3">
        <div class="col-6">
          <label for="email" class="form-label required">E-mail</label>
          <div class="input-group">
            <input type="email" class="form-control" [ngClass]="{'click-disabled': isEditando}" formControlName="email" id="email">
            <mat-icon class="icon edit-icon material-symbols-rounded" *ngIf="isEditando" (click)="enableEdit('email')">edit</mat-icon>
          </div>
        </div>

        <div class="col-2" formGroupName="endereco">
          <label for="cep" class="form-label required">CEP</label>
          <div class="input-group">
            <input type="text" class="form-control required"  [ngClass]="{'click-disabled': isEditando}" formControlName="cep" id="cep" appCepMask (blur)="getEnderecoViaCep()" [maxLength]="9">
            <mat-icon class="icon edit-icon material-symbols-rounded" *ngIf="isEditando" (click)="enableEdit('cep')">edit</mat-icon>
          </div>
        </div>

        <div class="col-4" formGroupName="endereco">
          <label for="localidade" class="form-label required">Cidade</label>
          <div class="input-group">
            <input type="text" class="form-control" [ngClass]="{'click-disabled': isEditando}" formControlName="localidade" id="localidade">
            <mat-icon class="icon edit-icon material-symbols-rounded" *ngIf="isEditando" (click)="enableEdit('localidade')">edit</mat-icon>
          </div>
        </div>
      </div>

      <div class="row g-3" formGroupName="endereco">
        <div class="col-6">
          <label for="logradouro" class="form-label required">Logradouro</label>
          <div class="input-group">
            <input type="text" class="form-control" [ngClass]="{'click-disabled': isEditando}" formControlName="logradouro" id="logradouro">
            <mat-icon class="icon edit-icon material-symbols-rounded" *ngIf="isEditando" (click)="enableEdit('logradouro')">edit</mat-icon>
          </div>
        </div>

        <div class="col-2">
          <label for="bairro" class="form-label required">Bairro</label>
          <div class="input-group">
            <input type="text" class="form-control" [ngClass]="{'click-disabled': isEditando}" formControlName="bairro" id="bairro">
            <mat-icon class="icon edit-icon material-symbols-rounded" *ngIf="isEditando" (click)="enableEdit('bairro')">edit</mat-icon>
          </div>
        </div>

        <div class="col-2">
          <label for="numero" class="form-label required">Número</label>
          <div class="input-group">
            <input type="text" class="form-control" [ngClass]="{'click-disabled': isEditando}" formControlName="numero" id="numero">
            <mat-icon class="icon edit-icon material-symbols-rounded" *ngIf="isEditando" (click)="enableEdit('numero')">edit</mat-icon>
          </div>
        </div>

        <div class="col-2">
          <label for="uf" class="form-label required">Estado</label>
          <div class="input-group">
            <input type="text" class="form-control" [ngClass]="{'click-disabled': isEditando}" formControlName="uf" id="uf" [maxLength]="2">
            <mat-icon class="icon edit-icon material-symbols-rounded" *ngIf="isEditando" (click)="enableEdit('uf')">edit</mat-icon>
          </div>
        </div>
      </div>

      <div class="row g-3" formGroupName="endereco">
        <div class="col-6">
          <label for="complemento" class="form-label">Complemento</label>
          <div class="input-group" style="padding-bottom: 0; margin-bottom: 0 !important;">
            <input type="text" class="form-control" [ngClass]="{'click-disabled': isEditando}" formControlName="complemento" id="complemento" style="margin-bottom: 0">
            <mat-icon class="icon edit-icon material-symbols-rounded" *ngIf="isEditando" (click)="enableEdit('complemento')">edit</mat-icon>
          </div>
          <span style="margin-top: 5px">Em caso de área rural, informar latitude e longitude</span>
        </div>
      </div>

    </form>
  </div>

  <ng-container *ngIf="empresaId">
    <app-administrador></app-administrador>
  </ng-container>

  <div class="actions-footer">
    <button class="exclude-button ocult-button" [disabled]="!isEditando" [ngClass]="{'exclude-disabled': !isEditando}">
      <mat-icon class="exclude-icon material-symbols-rounded">delete</mat-icon>
      <span>Excluir empresa</span>
    </button>

    <div class="buttons">
      <button class="button-no-border" style="margin-right: 10px" (click)="cleanInput()">Limpar dados</button>
      <button [disabled]="!form.valid || !form.dirty" (click)="openConfirm()" [ngClass]="{'button-disabled': !form.valid || !form.dirty}">
        {{ msgButton }}
      </button>
    </div>
  </div>

  <div id="overlayConfirm">
    <app-overlay-confirmacao [action]=" 'salvar'" (onSave)="onSubmit()"></app-overlay-confirmacao>
  </div>

  <div id="filterWall"></div>

</div>
