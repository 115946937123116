<div style="position: relative; min-height: 85vh">
    <div class="container-header">
        <div class="back-box">
            <a href="">
                <mat-icon class="icon back-icon material-symbols-rounded">arrow_back</mat-icon>
            </a>
            <span class="back-text">Voltar</span>
        </div>
        <!-- <div class="register-info">
        <span class="tec-info">
          <span>Cadastrado pelo técnico: </span>
          <span class="tec-name">Diogo Garbo</span>
        </span>

        <span class="date-info">
          <span>Última edição: </span>
          <span class="edited-date">08 de Maio, 2024</span>
        </span>
      </div> -->
    </div>

    <!-- Tecnico apenas -->
    <div class="finalizar-lote-container" *ngIf="this.storage.getRole() == 'TECNICO'">
        <div class="infos-finalizar-lote-box">
            <div class="info-finalizar-lote">
                <span class="info-title">Data de alojamento</span>
                <span class="info">{{ loteDetail?.dataAlojamento | dateFormat }}</span>
            </div>
            <div class="info-finalizar-lote">
                <span class="info-title">Peso de abate esperado</span>
                <span class="info">{{ loteDetail.pesoAbateEsperado}}.kg</span>
            </div>
            <div class="info-finalizar-lote">
                <span class="info-title">Remessa total</span>
                <span class="info">{{ loteDetail.qtdRecebida }}</span>
            </div>
            <div class="info-finalizar-lote">
                <span class="info-title">Espécie</span>
                <span class="info">{{ loteDetail.especie}}</span>
            </div>
        </div>

        <button class="finalizar-lote-button" (click)="popUpFinalizar()">Finalizar lote</button>
    </div>

    <div class="validar-lote-container" *ngIf="comprovanteEmAberto && storage.getRole() == 'PRODUTOR'">
        <div class="infos-validar-lote-box">
            <div class="info-validar-lote">
                <span class="info"><b>Lote 01</b> Registrado em: {{loteDetail?.dataAlojamento | dateFormat}}</span>
            </div>
        </div>

        <div class="buttons-box-validation">
            <button class="recusar-lote-button" (click)="popUpRecusar()">Recusar</button>
            <button class="validar-lote-button" (click)="aceitarLote()">Validar</button>
        </div>
    </div>

    <div class="container-historic">
        <span>Histórico de registro</span>

        <app-table-registro-historico></app-table-registro-historico>
    </div>

    <div class="dividing-line"></div>

    <form [formGroup]="form">
    <div class="container-form">
        <span class="container-infos-title">Informações do lote</span>

        <span class="container-infos-subtitle">Ambiente</span>
        <div class="row g-3">
            <div class="col-4">
                <label for="tanque" class="form-label">Tanque</label>
                <div class="input-group">
                    <input type="text" class="form-control click-disabled" id="tanque" formControlName="nomeTanque">
                </div>
            </div>

            <div class="col-4">
                <label for="lote" class="form-label">Lote</label>
                <div class="input-group">
                    <input type="text" class="form-control click-disabled" id="lote"  formControlName="lote">
                </div>
            </div>

            <div class="col-4">
                <label for="ciclo" class="form-label">Ciclo</label>
                <div class="input-group">
                    <input type="text" class="form-control click-disabled" id="ciclo"  formControlName="ciclo">
                </div>
            </div>
        </div>

        <div class="row g-3">
            <div class="col-4">
                <label for="tipoTanque" class="form-label">Tipo de tanque</label>
                <div class="input-group">
                    <input type="text" class="form-control click-disabled" id="tipoTanque" formControlName="tipoTanque">
                </div>
            </div>

            <div class="col-4">
                <label for="area" class="form-label">Área (m²)</label>
                <div class="input-group">
                    <input type="text" class="form-control click-disabled" id="area" formControlName="area">
                </div>
            </div>

            <div class="col-4">
                <label for="aeracaoTotal" class="form-label">Aeração total (CV)</label>
                <div class="input-group">
                    <input type="text" class="form-control click-disabled" id="aeracaoTotal" formControlName="potenciaAeracaoTotal">
                </div>
            </div>
        </div>

        <span class="container-infos-subtitle">Peixes</span>
        <div class="row g-3">
            <div class="col-4">
                <label for="especie" class="form-label">Espécie</label>
                <div class="input-group">
                    <input type="text" class="form-control click-disabled" id="especie" formControlName="especie">
                </div>
            </div>

            <div class="col-4">
                <label for="origem" class="form-label">Origem</label>
                <div class="input-group">
                    <input type="text" class="form-control click-disabled" id="origem" formControlName="origem">
                </div>
            </div>

            <div class="col-4">
                <label for="dataAlojamento" class="form-label">Data de alojamento</label>
                <div class="input-group">
                    <input type="text" class="form-control click-disabled" id="dataAlojamento" formControlName="dataAlojamento">
                </div>
            </div>
        </div>

        <div class="row g-3">
            <div class="col-4">
                <label for="primeiraRemessa" class="form-label">Qtd. recebida (1º remessa)</label>
                <div class="input-group">
                    <input type="text" class="form-control click-disabled" id="primeiraRemessa" formControlName="qtdRecebida">
                </div>
            </div>

            <div class="col-4">
                <label for="mortalidadeAlojamento" class="form-label">Mortalidade de Alojamento (7 dias)</label>
                <div class="input-group">
                    <input type="text" class="form-control click-disabled" id="mortalidadeAlojamento" formControlName="mortalidade">
                </div>
            </div>

            <div class="col-4">
                <label for="segundaRemessa" class="form-label">Qtd. recebida (2º remessa)</label>
                <div class="input-group">
                    <input type="text" class="form-control click-disabled" id="segundaRemessa" formControlName="qtdRecebida2">
                </div>
            </div>
        </div>

        <div class="row g-3">
            <div class="col-4">
                <label for="pesoMedio" class="form-label">Peso médio (Kg)</label>
                <div class="input-group">
                    <input type="text" class="form-control click-disabled" id="pesoMedio" formControlName="pesoMedio">
                </div>
            </div>

            <div class="col-4">
                <label for="biomassaTotal" class="form-label">Biomassa total alojada (Kg)</label>
                <div class="input-group">
                    <input type="text" class="form-control click-disabled" id="biomassaTotal" formControlName="biomassaTotal">
                </div>
            </div>

            <div class="col-4">
                <label for="densidade" class="form-label">Densidade (Px/m²)</label>
                <div class="input-group">
                    <input type="text" class="form-control click-disabled" id="densidade" formControlName="densidade">
                </div>
            </div>
        </div>

        <div class="row g-3">
            <div class="col-4">
                <label for="biomassaAtual" class="form-label">Biomassa por CV Atual (Kg)</label>
                <div class="input-group">
                    <input type="text" class="form-control click-disabled" id="biomassaAtual" formControlName="biomassaCVAtual">
                </div>
            </div>

            <div class="col-4">
                <label for="pesoAbateEsperado" class="form-label">Peso de abate esperado (Kg)</label>
                <div class="input-group">
                    <input type="text" class="form-control click-disabled" id="pesoAbateEsperado" formControlName="pesoAbateEsperado">
                </div>
            </div>

            <div class="col-4">
                <label for="biomassaEstimada" class="form-label">Biomassa estimada/CV fase final (Kg/CV)</label>
                <div class="input-group">
                    <input type="text" class="form-control click-disabled" id="biomassaEstimada" formControlName="biomassaEstimadaFinal">
                </div>
            </div>
        </div>

        <span class="container-infos-title">Comprovante de entrega</span>

        <span class="container-infos-subtitle">Geral</span>
        <div class="row g-3">
            <div class="col-4">
                <label for="data" class="form-label">Data</label>
                <div class="input-group">
                    <input type="text" class="form-control click-disabled" id="data" formControlName="data">
                </div>
            </div>

            <div class="col-4">
                <label for="saida" class="form-label">Saída</label>
                <div class="input-group">
                    <input type="text" class="form-control click-disabled" id="saida" formControlName="saida">
                </div>
            </div>

            <div class="col-4">
                <label for="entrada" class="form-label">entrada</label>
                <div class="input-group">
                    <input type="text" class="form-control click-disabled" id="entrada" formControlName="entrada">
                </div>
            </div>
        </div>

        <div class="row g-3">
            <div class="col-4">
                <label for="temperaturaAgua" class="form-label">Temperatura da água</label>
                <div class="input-group">
                    <input type="text" class="form-control click-disabled" id="temperaturaAgua" formControlName="temperaturaAgua">
                </div>
            </div>

            <div class="col-4">
                <label for="oxigenio" class="form-label">Oxigênio</label>
                <div class="input-group">
                    <input type="text" class="form-control click-disabled" id="oxigenio" formControlName="oxigenio">
                </div>
            </div>

            <div class="col-4">
                <label for="placaVeiculo" class="form-label">Placa do veículo</label>
                <div class="input-group">
                    <input type="text" class="form-control click-disabled" id="placaVeiculo" formControlName="placaVeiculo">
                </div>
            </div>
        </div>

        <span class="container-infos-subtitle">Carregamento</span>
        <div class="row g-3">
            <div class="col-4">
                <label for="nmrCaixas" class="form-label">Nº de caixas</label>
                <div class="input-group">
                    <input type="text" class="form-control click-disabled" id="nmrCaixas" formControlName="numeroCaixas">
                </div>
            </div>

            <div class="col-4">
                <label for="peixePorCaixa" class="form-label">Qtd. de peixes por caixa</label>
                <div class="input-group">
                    <input type="text" class="form-control click-disabled" id="peixePorCaixa" formControlName="peixePorCaixa">
                </div>
            </div>

            <div class="col-4">
                <label for="pesoMedioCaixa" class="form-label">Peso médio (g)</label>
                <div class="input-group">
                    <input type="text" class="form-control click-disabled" id="pesoMedioCaixa" formControlName="pesoMedioComprovante">
                </div>
            </div>
        </div>

        <div class="row g-3">
            <div class="col-4">
                <label for="tanqueAlocado" class="form-label">Tanque alocado</label>
                <div class="input-group">
                    <input type="text" class="form-control click-disabled" id="tanqueAlocado" formControlName="nomeTanque">
                </div>
            </div>

            <div class="col-4">
                <label for="pesoTotalCaixa" class="form-label">Peso total (Kg)</label>
                <div class="input-group">
                    <input type="text" class="form-control click-disabled" id="pesoTotalCaixa" formControlName="pesoTotal">
                </div>
            </div>

            <div class="col-4">
                <label for="nmrLacre" class="form-label">Nº do lacre</label>
                <div class="input-group">
                    <input type="text" class="form-control click-disabled" id="nmrLacre" formControlName="numeroLacre">
                </div>
            </div>
        </div>

        <span class="container-infos-subtitle">Observações</span>
        <div class="row g-3">
            <div class="col-12">
                <label for="anotacaoGeral" class="form-label">Anotações gerais sobre o lote</label>
                <div class="input-group">
                    <input type="text" class="form-control click-disabled" id="anotacaoGeral">
                </div>
            </div>
        </div>
    </div>
</form>

    <div id="popupWall"></div>

    <div id="overlayEndLote">
        <div class="overlay-end-lote-header">
            <span>Finalizar lote!</span>
            <mat-icon class="icon-close material-symbols-rounded" (click)="closePopup()">close</mat-icon>
        </div>
        <div class="overlay-end-lote-content">
            <div class="overlay-end-lote-text-box">
                <div class="col-12">
                    <label for="dataFinalizar" class="form-label label-popup">Data</label>
                    <div>
                        <input type="date" class="form-control form-popup" id="dataFinalizar" [(ngModel)]="dataFinalizar">
                    </div>
                </div>

                <div class="col-12">
                    <label for="horaFinalizar" class="form-label label-popup">Hora</label>
                    <div>
                        <input type="time" class="form-control form-popup" id="horaFinalizar" [(ngModel)]="horaFinalizar">
                    </div>
                </div>
            </div>

            <div class="overlay-end-lote-buttons">
                <button class="back-end-lote-button button-white" (click)="closePopup()">Cancelar</button>
                <button class="end-lote-button" (click)="finalizarLote(dataFinalizar, horaFinalizar)">Finalizar</button>
            </div>
        </div>
    </div>

    <div id="overlayValidationLote">
        <div class="overlay-validation-lote-header">
            <span>justifique a resposta</span>
            <mat-icon class="icon-close material-symbols-rounded" (click)="closePopUpRecusar()">close</mat-icon>
        </div>
        <div class="overlay-validation-lote-content">
            <div class="overlay-validation-lote-subtitle">Nos informe o erro que já iremos corrigir</div>
            <div class="overlay-validation-lote-text-box">
                <div class="col-12">
                    <label class="form-label label-validation-message-box">Faça sua observação</label>
                    <div>
                        <textarea placeholder="" class="observacao-lote-text" id="observacaoLoteText"></textarea>
                    </div>
                </div>
            </div>

            <div class="overlay-validation-lote-buttons">
                <button class="back-validation-lote-button button-white" (click)="cleanTextArea()">Limpar</button>
                <button class="validation-lote-button" (click)="recusarLote()">Enviar</button>
            </div>
        </div>
    </div>

</div>
