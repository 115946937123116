import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { StorageService } from "../storage.service";

@Injectable({
    providedIn: 'root'
})
export class ComprovanteService {
     private apiUrl = '//' + document.location.hostname + '/comprovante';
    private headers = new HttpHeaders().set('Authorization', this.storage.getToken());

    constructor(private http: HttpClient,
        private storage: StorageService) { }

    save(data: any): Observable<any> {
        return this.http.post<any>(`${this.apiUrl}/save/record`, data, { headers: this.headers });
    }

    getTanques(produtorId: any): Observable<any> {
        return this.http.get<any>(`${this.apiUrl}/list-lotes/${produtorId}`, { headers: this.headers });
    }

    emAberto(loteId: any): Observable<any> {
        return this.http.get<any>(`${this.apiUrl}/not-accepted/${loteId}`, { headers: this.headers });
    }

    validarLote(loteId: any): Observable<any> {
        return this.http.patch<any>(`${this.apiUrl}/update-dt-aceite/${loteId}`, { headers: this.headers });
    }

    updateRecusarLote(id: any, isRecusado: boolean): Observable<any> {
        return this.http.patch<any>(`${this.apiUrl}/update-recusa/${id}/${isRecusado}`, { headers: this.headers });
    }

    existsLoteByTanque(tanqueId: any): Observable<any> {
        return this.http.get<any>(`${this.apiUrl}/accepted-tanque/${tanqueId}`, { headers: this.headers });
    }

    existsLoteByLote(loteId: any): Observable<any> {
        return this.http.get<any>(`${this.apiUrl}/accepted-lote/${loteId}`, { headers: this.headers });
    }


}
