<div class="geral">
  <div class="login-container">
    <img src="assets/conectre.svg" alt="">
    <ng-container *ngIf="redefineView">
      <div class="login-form">
        <form [formGroup]="newPassword" (ngSubmit)="redefinePassword()">
          <mat-progress-bar mode="determinate" value="60"></mat-progress-bar>
          <h2 style="margin-bottom: 10px">Redefina sua senha</h2>
          <h4>Agora, vamos criar uma nova senha para você <br> com no <b>mínimo 6 caracteres</b>.</h4>
          <div class="form-group">
            <input type="password" class="form-control" id="password" formControlName="password" placeholder="Nova senha" required>
            <mat-icon class="icon-visibility-password toggle-password material-symbols-rounded" (click)="togglePasswordVisibility()">{{ iconStatus }}</mat-icon>
          </div>
          <div class="form-group">
            <input type="password" class="form-control" id="passwordCheck" formControlName="passwordCheck" placeholder="Confirmar senha" required>
          </div>
          <div class="form-group">
            <input type="submit" value="Confirmar nova senha" [disabled]="!newPassword.valid" [ngClass]="{'button-disabled': !newPassword.valid}">
          </div>
        </form>
      </div>
    </ng-container>

    <ng-container *ngIf="showContent">
      <div class="login-form">
        <mat-progress-bar mode="determinate" value="100"></mat-progress-bar>
        <img src="assets/check.svg" class="icons" alt="">
        <h2 style="margin-bottom: 10px">Senha redefinida!</h2>
        <h4>Utilize agora sua nova senha para entrar <br> em sua plataforma</h4>
        <div class="form-group">
          <input type="submit" value="Voltar ao início" (click)="showLoginView()">
        </div>
      </div>
    </ng-container>
  </div>
</div>


