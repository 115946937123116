<div class="container-filter" style="margin-top: 15px;">
    <div class="input-search-box">
      <input class="input-search" type="text" placeholder="Buscar registro?" [(ngModel)]="filter">
      <mat-icon class="icon-search material-symbols-rounded" (click)="search()">search</mat-icon>
    </div>
  </div>
  
  
  <div class="mat-elevation-z2">
    <table mat-table [dataSource]="dataSource">
  
      <ng-container matColumnDef="tanque">
        <th mat-header-cell class="tanque-column" *matHeaderCellDef id="tanque">Tanque</th>
        <td mat-cell class="tanque-column" *matCellDef="let element"> {{ element.tanqueNome }}</td>
      </ng-container>
  
      <ng-container matColumnDef="propriedade">
        <th mat-header-cell class="propriedade-column" *matHeaderCellDef id="local">Propriedade</th>
        <td mat-cell class="propriedade-column" *matCellDef="let element"> {{ element.propriedadeNome }}</td>
      </ng-container>
  
      <ng-container matColumnDef="convAlimentar">
        <th mat-header-cell *matHeaderCellDef id="convAlimentar">C.A</th>
        <td mat-cell *matCellDef="let element"> {{ element?.ca }}</td>
      </ng-container>
  
      <ng-container matColumnDef="gpd">
        <th mat-header-cell *matHeaderCellDef id="gpd">GPD</th>
        <td mat-cell *matCellDef="let element">{{ element.gpd }}kg</td>
      </ng-container>
  
      <ng-container matColumnDef="totalRecebida">
        <th mat-header-cell *matHeaderCellDef id="totalRecebida">Total recebida</th>
        <td mat-cell *matCellDef="let element">{{ element.tlRecebida }}</td>
      </ng-container>
  
      <ng-container matColumnDef="alojamento">
        <th mat-header-cell *matHeaderCellDef id="alojamento">Alojamento</th>
        <td mat-cell *matCellDef="let element">{{ element.alojamento | dateFormat }}</td>
      </ng-container>
  
      <ng-container matColumnDef="despeca">
        <th mat-header-cell class="despeca-column" *matHeaderCellDef id="despeca">Despesca</th>
        <td mat-cell class="despeca-column" *matCellDef="let element">{{ element.despesca | dateFormat }}</td>
      </ng-container>
  
      <ng-container matColumnDef="diasCultivo">
        <th mat-header-cell class="data-dias-cultivo-column" *matHeaderCellDef id="diasCultivo">Dias de cultivo</th>
        <td mat-cell class="data-dias-cultivo-column" *matCellDef="let element">{{ element.diasCultivo}}</td>
      </ng-container>
  
      <ng-container matColumnDef="detalhe">
        <th mat-header-cell class="detalhe-column" *matHeaderCellDef id="header-right">Detalhes</th>
        <td mat-cell class="detalhe-column" *matCellDef="let element" id="detalhe">
          <mat-icon class="icon-search-info material-symbols-rounded" (click)="redirectToDetails(element?.id)">search</mat-icon>
        </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  
    <div id="paginacao" class="flex-align-itens-center">
      <mat-icon class="arrow-table-back material-symbols-rounded" (click)="nextOrBack(false)">outbound</mat-icon>
      <div class="flex-align-itens-center">
        <ng-container *ngFor="let number of getSequence(totalPage)">
          <span id="paginacao-number" (click)="paginado(number)">{{ number }}</span>
        </ng-container>
      </div>
      <mat-icon class="arrow-table-next material-symbols-rounded" (click)="nextOrBack(true)">outbound</mat-icon>
    </div>
  </div>
  
  