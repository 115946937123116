import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { StorageService } from "../storage.service";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MedicaoNovoService {

   private apiUrl = '//' + document.location.hostname + '/medicao-novo';
  private headers = new HttpHeaders().set('Authorization', this.storage.getToken());

  constructor(private http: HttpClient,
    private storage: StorageService) { }

  save(data: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/save/record`, data, { headers: this.headers });
  }

  historicoAgua(loteId: number, number: any, size: any): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/historico-agua/${loteId}/${number}/${size}`, { headers: this.headers });
  }

  historicoBiometria(loteId: number, number: any, size: any): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/historico-biometria/${loteId}/${number}/${size}`, { headers: this.headers });
  }

  historicoTrato(loteId: number, number: any, size: any): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/historico-trato/${loteId}/${number}/${size}`, { headers: this.headers });
  }

  historicoEstoque(loteId: number, number: any, size: any): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/historico-estoque/${loteId}/${number}/${size}`, { headers: this.headers });
  }

}
