<ng-container *ngIf="isLoggedIn">
  <mat-sidenav-container>
    <mat-sidenav class="transition-effect" opened mode="side" [style.width]="sidenavWidth()">
      <app-sidebar [collapse]="collapsed()"></app-sidebar>

      <div class="sidebar-button" (click)="openSideMenu()" (click)="collapsed.set(!collapsed())">
        <span class="menu-icon-line first-line"></span>
        <span class="menu-icon-line second-line"></span>
        <span class="menu-icon-line third-line"></span>
      </div>
    </mat-sidenav>

    <mat-sidenav-content class="content transition-effect" [style.margin-left]="sidenavWidth()">
      <div class="navbar-box">
        <!-- <div class="cabecalho" [ngClass]="{ 'absolute': isHeaderFixed }"> -->
        <div class="cabecalho">
          <h2 class="titulo">{{ storage?.PAGE_TITLE ? storage.PAGE_TITLE : 'Visualização geral' }}</h2>

          <div class="user-box">
            <img src="assets/avatar_icon.svg" class="img-avatar" alt="Imagem Redonda">
            <div class="user-name-and-icon-box">
              <h2 class="user-msg">Olá, <b>{{ storage.getUser() }}</b></h2>
              <mat-icon class="menu-arrow-icon material-symbols-rounded"
                (click)="openMenu()">keyboard_arrow_down</mat-icon>
              <div class="user-menu">
                <button class="menu-button" (click)="openConfirmLogout()">
                  <mat-icon class="logout-icon material-symbols-rounded">door_open</mat-icon>
                  <span class="logout-span">Sair</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <router-outlet style="margin: 20px;"></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</ng-container>

<ng-container *ngIf="!isLoggedIn">
  <router-outlet></router-outlet>
</ng-container>

<div id="overlayLogoutConfirm">
  <!-- <app-overlay-confirmacao [action]=" 'logout' " (onLogout)="onLogout()"></app-overlay-confirmacao> -->
  <div class="overlay-logout-header">
    <span>Atenção!</span>
    <mat-icon class="icon-close material-symbols-rounded" (click)="closeConfirm()">close</mat-icon>
  </div>
  <div class="overlay-logout-content">
    <span class="overlay-logout-text">Você deseja realmente fazer logout de seu usuário?</span>
    <div class="overlay-logout-buttons">
      <button class="confirm-logout-button" (click)="onLogout()">Fazer logout</button>
      <button class="cancel-logout-button button-white" (click)="closeConfirm()">Cancelar</button>
    </div>
  </div>
</div>

<div id="filterWall"></div>