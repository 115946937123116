<div class="container-full">
  <img class="logo-full" src="assets/conectre-72.svg">

  <div class="itens">
    <h4 class="topic hidden-effect invisible-effect">Gestão</h4>
    <ul>
      <ng-container *ngFor="let g of gestaoItems()">
        <li><a (click)="navigateTo(g.route)" class="navigate-button"><mat-icon class="icon material-symbols-rounded">{{ g.icon }}</mat-icon>
            <span class="item" *ngIf="!sideNavCollapsed">{{ g.title }}</span>
        </a></li>
      </ng-container>
      <li class="dropdown">
        <a class="cadastros-button" (click)="openDropdown()">
          <mat-icon class="icon material-symbols-rounded">person_add</mat-icon>
          <span class="item" *ngIf="!sideNavCollapsed">Cadastros</span>
          <mat-icon class="arrow-icon material-symbols-rounded hidden-effect invisible-effect">keyboard_arrow_down</mat-icon>
        </a>
        <div class="dropdown-content">
          <!-- <a (click)="redirectToDashboard()" *ngIf="storage.getRole() === 'ADM_TREVISAN' || storage.getRole() === 'EMPRESA'"><span class="cadastro-icon"></span> Dashboards</a> -->
          <a (click)="redirectToEmpresa()" *ngIf="storage.getRole() === 'ADM_TREVISAN' || storage.getRole() === 'EMPRESA'"><span class="cadastro-icon"></span> Empresa</a>
          <a (click)="redirectToTecnico()" *ngIf="storage.getRole() === 'ADM_TREVISAN' || storage.getRole() === 'EMPRESA'"><span class="cadastro-icon"></span> Técnico</a>
          <a (click)="redirectToProdutor()" *ngIf="storage.getRole() === 'ADM_TREVISAN' || storage.getRole() === 'TECNICO' || storage.getRole() === 'EMPRESA'"><span class="cadastro-icon"></span> Produtor</a>
          <a (click)="redirectToPropriedade()" *ngIf="storage.getRole() === 'ADM_TREVISAN' || storage.getRole() === 'TECNICO'"><span class="cadastro-icon"></span> Propriedade</a>

          <!-- <a (click)="true" *ngIf="storage.getRole() === 'ADM_TREVISAN' || storage.getRole() === 'TECNICO'"><span class="cadastro-icon"></span> Tanques</a>
          <a (click)="true" *ngIf="storage.getRole() === 'ADM_TREVISAN' || storage.getRole() === 'TECNICO'"><span class="cadastro-icon"></span> Lote</a> -->
        </div>
      </li>
    </ul>
  </div>

<!--  <div class="itens">-->
<!--    <h4 class="topic topic-child-2 hidden-effect invisible-effect">Sistema</h4>-->
<!--    <ul>-->
<!--      <ng-container *ngFor="let g of sistemaItems()">-->
<!--        <li class="sidemenu-secondary"><a href="{{ g.route }}"><mat-icon class="icon material-symbols-rounded">{{ g.icon }}</mat-icon>-->
<!--            <span class="item" *ngIf="!sideNavCollapsed">{{ g.title }}</span></a></li>-->
<!--      </ng-container>-->
<!--    </ul>-->
<!--  </div>-->

  <!-- <div class="menu-button" (click)="openSideMenu()">
    <span class="menu-icon-line first-line"></span>
    <span class="menu-icon-line second-line"></span>
    <span class="menu-icon-line third-line"></span>
  </div> -->
</div>
