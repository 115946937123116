<div class="geral">
  <div class="login-container">
    <img src="assets/conectre.svg">
    <ng-container *ngIf="showLogin">
      <div class="login-form">
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
          <h1 style="padding-bottom: 40px">Login</h1>
          <div class="form-group">
            <input type="email" class="form-control" name="username" id="username" formControlName="username" placeholder="E-mail" required>
          </div>
          <div class="form-group">
            <input type="password" class="form-control" name="password" id="password" formControlName="password" placeholder="Senha" required>
            <mat-icon class="icon-visibility-password toggle-password material-symbols-rounded" (click)="togglePasswordVisibility()">{{ iconStatus }}</mat-icon>
          </div>
          <div *ngIf="isLogged" class="error-message">
            E-mail ou Senha incorretos.
          </div>
          <div *ngIf="error" class="error-message">
            E-mail ou senha não cadastrado.
          </div>
          <div class="form-group">
            <input type="submit" value="Entrar" [disabled]="!loginForm.valid" [ngClass]="{'button-disabled': !loginForm.valid}">
          </div>
        </form>
        <a class="forgot-password" (click)="showForgotPassword()">Esqueci minha senha</a>
      </div>
    </ng-container>
    <ng-container *ngIf="showForgotPasswordContent">
      <div class="login-form">
        <form [formGroup]="forgotPasswordForm">
          <mat-progress-bar mode="determinate" value="30"></mat-progress-bar>
          <h2 style="margin-bottom: 10px">Esqueceu a senha?</h2>
          <h4>Nos informe seu e-mail para enviarmos <br> um link para redefinir sua senha</h4>
          <div class="form-group">
            <input type="email" formControlName="username" placeholder="E-mail" required>
          </div>
          <div class="form-group">
            <input type="submit" value="Enviar link de redefinição" (click)="redefinePassword()" [disabled]="!forgotPasswordForm.valid" [ngClass]="{'button-disabled': !forgotPasswordForm.valid}">
          </div>
        </form>
      </div>
      <a class="back" (click)="showLoginView()">Voltar ao login</a>
    </ng-container>

    <ng-container *ngIf="showContent">
      <div class="login-form">
        <mat-progress-bar mode="determinate" value="30"></mat-progress-bar>
        <img src="assets/check.svg" class="icons">
        <h2 style="margin-bottom: 10px">E-mail enviado!</h2>
        <h4>Verifique sua caixa de e-mail e spam <br> para prosseguirmos e redefinir sua senha</h4>
        <div class="form-group">
          <input type="submit" value="Voltar ao início" (click)="showLoginView()">
        </div>
      </div>
    </ng-container>
  </div>
</div>


