<mat-expansion-panel style="margin: 40px 40px 0 40px; background: transparent;" [(expanded)]="isPanelExpanded">
  <mat-expansion-panel-header (click)="animationArrow()">
    <mat-panel-title>
      <span style="font-size: 16px">Administradores cadastrados</span>
      <div class="nmr-licencas">
        <span class="license-info">{{ administradores?.length }} / 5 licenças utilizadas</span>
        <mat-icon class="license-arrow-icon material-symbols-rounded">keyboard_arrow_down</mat-icon>
      </div>
    </mat-panel-title>
  </mat-expansion-panel-header>

  <div class="panel-contents" style="margin: 20px 20px 40px 20px;">
    <div class="infos-content" style="color: #52525B;">
      <span style="margin-bottom: 20px;"><b>Plano start: </b>{{ administradores?.length }}/5 licenças</span>
      <span style="font-size: 12px;" *ngIf="!existAdm">Nenhum administrador cadastrado</span>
    </div>

    <div class="buttons">
      <button class="button-no-border exclude-button" (click)="deletAdm = !deletAdm">Excluir</button>
      <button [disabled]="administradores.length >= 5" class="button-white license-info" (click)="adicionarAdm(null)"
        [ngClass]="{'button-disabled':  administradores.length >= 5}">Novo administrador</button>
    </div>
  </div>

  <div class="container-adms" style="margin: 0px">
    <div *ngFor="let item of administradores" class="avatar">
      <div class="avatar-circle" (click)="editarAdm(item.id)">{{ getInitials(item.nome) }}</div>
      <p>{{ item.nome }}</p>
      <ng-container *ngIf="deletAdm">
        <mat-icon class="exclude-icon material-symbols-rounded"
          (click)="callDeletePopUp(item.id, item.nome)">delete</mat-icon>
      </ng-container>
    </div>
  </div>

</mat-expansion-panel>

<div id="overlayAdm">
  <app-administrador-cadastro [admId]="admId" (onSave)="reloadAdm()"></app-administrador-cadastro>
</div>

<div id="filterWall"></div>

<div id="overlayExclusion">
  <!-- <app-overlay-confirmacao [action]=" 'deletar'" (onDelete)="onDelete()"></app-overlay-confirmacao> -->

  <div class="overlay-delete-header">
    <span>Atenção!</span>
    <mat-icon class="icon-close material-symbols-rounded" (click)="closeConfirm()">close</mat-icon>
  </div>
  <div class="overlay-delete-content">
    <span class="overlay-delete-text">Deseja realmente excluir o administrador {{ deleteAdmNome }}?</span>
    <div class="overlay-delete-buttons">
      <button class="confirm-delete-button" (click)="onDelete()">Excluir</button>
      <button class="cancel-delete-button button-white" (click)="closeConfirm()">Cancelar</button>
    </div>
  </div>
</div>